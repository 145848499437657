import React, { useEffect } from "react";
import "./prizes.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { newImages, prizeBeautyLogo } from "../../images";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { accountRegister } from "../../helpers/constant-words";

const Prizes = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main>
        <CommonBanner
          imgSrc={
            width > 600
              ? newImages.prizeBanner.image
              : newImages.mbPrizeBanner.image
          }
          title={`THE ${width < 600 ? "<br />" : ""}PRIZES`}
        />
        <section className="prize_sec1">
          <div className="my_container">
            <div className="sec1_wrapper">
              <div className="text_container">
                <h1 className="section_subheading">PRIZES</h1>
                <p className="section_desc prize_desc">
                  In addition to a total prize pool of INR 4 crores ($500,000
                  USD), each company will have the potential to build a
                  long-term partnership with The Estée Lauder Companies’ New
                  Incubation Ventures (the strategic early-stage investing and
                  incubation arm of The Estée Lauder Companies).
                </p>
                <div className="cta_wrapper">
                  <Link to={accountRegister} className="project-button">
                    APPLY NOW
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="prize_category grow_sec">
          <div className="my_container">
            <div className="sec2_wrapper">
              <div className="text_container">
                <h1 className="section_subheading category_heading">
                  GROW PRIZE
                </h1>
                <p className="section_desc prize_desc">
                  In-market beauty concepts in the categories of skin care, make
                  up, hair care, personal fragrance and home fragrance
                </p>
                <p className="section_desc prize_amount">
                  UP TO INR 1.25 crores / $150K USD
                </p>
                <ul className="highlights_list">
                  <li className="list-item">
                    National and International Press support
                  </li>
                  <li className="list-item">
                    Exposure on the Estée Lauder, New Incubation Ventures and
                    Nykaa social channels
                  </li>
                  <li className="list-item">
                    Distribution with{" "}
                    <a
                      className="external"
                      href="https://www.nykaa.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      nykaa.com
                    </a>{" "}
                    upon launch
                  </li>
                  <li className="list-item mentorship_list_item">
                    Mentorship and resource support via ELC, Nykaa and related
                    partners
                  </li>
                  <li className="list-item mentorship_list_item">
                    EXCLUSIVE BEAUTY&YOU BOOTCAMP WITH INDIA’S LEADING FOUNDERS
                    AND EXPERTS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="prize_category imagine_sec">
          <div className="my_container">
            <div className="sec2_wrapper">
              <div className="text_container">
                <h1 className="section_subheading category_heading">
                  IMAGINE PRIZE
                </h1>
                <p className="section_desc prize_desc imagine_prize_desc">
                  Pre-launch beauty concepts in the categories of skin care,
                  make up, hair care, personal fragrance and home fragrance
                </p>
                <p className="section_desc prize_amount">
                  UP TO INR 62 lakhs / $75K USD
                </p>
                <ul className="highlights_list">
                  <li className="list-item">
                    National and International Press support
                  </li>
                  <li className="list-item">
                    Exposure on the Estée Lauder, New Incubation Ventures and
                    Nykaa social channels
                  </li>
                  <li className="list-item mentorship_list_item">
                    Mentorship and resource support via ELC, Nykaa and related
                    partners
                  </li>
                  <li className="list-item">
                    Distribution with{" "}
                    <a
                      className="external"
                      href="https://www.nykaa.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      nykaa.com
                    </a>{" "}
                    upon launch
                  </li>
                  <li className="list-item mentorship_list_item">
                    EXCLUSIVE BEAUTY&YOU BOOTCAMP WITH INDIA’S LEADING FOUNDERS
                    AND EXPERTS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="prize_category create_sec">
          <div className="my_container">
            <div className="sec2_wrapper">
              <div className="text_container">
                <h1 className="section_subheading category_heading">
                  CREATE PRIZE
                </h1>
                <p className="section_desc prize_desc">
                  The next generation of creative talent (eg: photographers,
                  filmmakers, etc.)
                </p>
                <p className="section_desc prize_amount">
                  UP TO INR 41 lakhs / $50K USD
                </p>
                <ul className="highlights_list">
                  <li className="list-item">
                    National and international press support
                  </li>
                  <li className="list-item">
                    Exposure on the Estée Lauder, New Incubation Ventures and
                    Nykaa social channels
                  </li>
                  <li className="list-item create_mentorship_list_item">
                    Mentorship and resource support via ELC and related partners
                  </li>
                  <li className="list-item mentorship_list_item">
                    EXCLUSIVE BEAUTY&YOU BOOTCAMP WITH INDIA’S LEADING FOUNDERS
                    AND EXPERTS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="prize_bootcamp">
          <div className="my_container">
            <img src={prizeBeautyLogo} alt="logo" className="logo" />
            <div className="bootcamp_content">
              <h4 className="bootcamp_title">Bootcamp</h4>
              <p className="bootcamp_desc">
                An exciting addition to this year's program is that winners will
                have access to the BEAUTY&YOU India Bootcamp, featuring
                one-on-one mentorship sessions with industry leaders Falguni
                Nayar, Founder & Chief Executive Officer, NYKAA; Sabyasachi
                Mukherjee, Founder, Sabyasachi; and Rohan Vaziralli, General Manager,
                ELCA Cosmetics Private Limited.
                <br /> <br />
                This unique opportunity is designed to provide the winners with
                invaluable insights and mentorship from these experts and
                affirms BEAUTY&YOU India’s commitment to fostering the growth
                and development of the next generation of Indian beauty
                entrepreneurs.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Prizes;
