import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
// import DatePicker from "react-datepicker";
import Select from "react-select";
import { faqClose, inputdownarrow } from "../../../images";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import userContext from "../../../context/User/UserContext";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";

const Step1 = ({ currentStep, nextStep, goToStep }) => {
  const User = useContext(userContext);

  const { width: windowWidth } = useWindowSize();
  // const [startDate, setStartDate] = useState(null);
  // const [headquaterLocation, setHeadquaterLocation] = useState({
  //   value: "Nawde",
  //   label: "Nawde",
  // });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [countryCode, setCountryCode] = useState();

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: "white",
      fontWeight: isSelected ? "normal" : "bold",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "grey" : "white",
        color: "#000",
        fontSize: windowWidth > 600 ? "12px" : "10px",
        fontWeight: isFocused ? "bold" : "normal",
        fontWeight: isSelected ? "bold" : "normal",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  // const [areYouUniversityStudent, setAreYouUniversityStudent] = useState("");

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("country", User.data.country);
    setValues({
      first_name: User.data.first_name,
      last_name: User.data.last_name,
      title: User.data.title,
      comp_name: User.data.comp_name,
      country: User.data.country || 101,
      address1: User.data.address1,
      address2: User.data.address2,
      state: User.data.state,
      city: User.data.city,
      zip: User.data.zip,
      country_code: User.data.country_code || "+91",
      phone: User.data.phone,
      // company_phone: User.data.company_phone,
      is_university_student: User.data.is_university_student,
      university_name: User.data.university_name,
      is_breakthrough_prize: User.data.is_breakthrough_prize,
      is_creative_prize: User.data.is_creative_prize,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;
  let redioButtonValidationMessage = {
    "string.empty": `Please select an option`,
  };
  const validate = async (data) => {
    let schemaObj = {
      first_name: Joi.string().required().label("First name"),
      last_name: Joi.string().required().label("Last name"),
      title: Joi.string().required().label("Title"),
      comp_name: Joi.string().trim().required().label("Company Name"),
      country: Joi.required().label("Headquarter Location"),
      address1: Joi.string().trim().required().label("Street Address 1"),
      state: Joi.required().label("State"),
      // city: Joi.required().label("City"),
      zip: Joi.string().required().label("Post Code"),
      is_university_student: Joi.string()
        .required()
        .label("This")
        .messages(redioButtonValidationMessage),
      // is_breakthrough_prize: Joi.string()
      //   .required()
      //   .label("This")
      //   .messages(redioButtonValidationMessage),
      is_creative_prize: Joi.string()
        .required()
        .label("This")
        .messages(redioButtonValidationMessage),

      phone: Joi.number().required().label("Phone Number"),
      // company_phone: Joi.number().allow("").label("Company Phone"),
    };
    console.log("city length", city.length);
    console.log("state length", state.length);
    // if(city.length > 0){
    //   schemaObj.city = Joi.required().label("City");
    // }

    if (data.is_university_student == "yes") {
      schemaObj.university_name = Joi.string()
        .trim()
        .required()
        .label("University name");
    }
    // if (data.is_breakthrough_prize == "no") {
    //   schemaObj.is_creative_prize = Joi.string()
    //     .required()
    //     .label("This")
    //     .messages(redioButtonValidationMessage);
    // }
    console.log({ schemaObj });
    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (state.length > 0 && data.state == 0) {
      errors["state"] = "State is required";
    }

    if (city.length > 0 && data.city == 0) {
      errors["city"] = "City is required";
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    console.log({ errors });
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    let form_values = values;

    if (form_values.is_university_student == "no") {
      form_values.university_name = "";
    }

    if (form_values.is_creative_prize == "yes") {
      form_values.buss_overview = "";
      form_values.hear_source = "";
      form_values.other_hear_source = "";
    }

    const growFields = [
      // step 2
      // "company_website",
      // "facebook",
      // "youtube",
      // "instagram",
      // "linkedin",
      // "tiktok",
      // "snapchat",
      // "other_social",

      // step 3
      "buss_type",
      "gst_no",
      "emp_count",
      "buss_ref_name",
      "buss_ref_designation",
      "buss_ref_email",
      "comp_launch_date",
      "comp_launch_month",
      "comp_launch_year",

      // step 5
      "buss_overview",
      // step 6
      "buss_video_link",

      //step 7
      "selling_product_category",
      "target_age",
      "other_selling_product_category",
      "other_target_age",

      //step 8
      "elevator_pitch",
      "brand_desc",
      "addressing_opp",
      "brand_exp",

      //step 9
      "sustain_focus_category",
      "other_sustain_focus_category",
      "is_sustain_mission",

      //step 10
      "distribution_channel",
      "other_distribution_channel",
      
      "revenue_2022",
      "revenue_india",
      "traction_info",
      "investment_to_date",

      //step 11
      "market_win_reason",
      "extra_info",

      "mission",

      "buss_overview",
      "buss_video_link",
    ];

    const breakthroughFields = [
      // step 23
      "addressing_ingredient",
      "other_addressing_ingredient",

      // step 24
      "elevator_pitch",
      "company_background",
      "efficacy_evidence",
      // step 25
      "ingredient_market",
      "applicable_reason",
      "is_ingredient_sustain",
      "ingredient_sustain_reason",
      // step 26
      "traction_info",
      // step 27
      "market_win_reason",
      "extra_info",
      "breakthrough_in_currently_market",
      "estee_discussion",
      "buss_overview",
      "buss_video_link",
    ];
    const creativeFields = [
      // step 31
      "work_summary",
      "creative_background",
      "buss_overview",
      "buss_video_link",
    ];

    // if (form_values.is_breakthrough_prize == "yes") {
    //   growFields.forEach((field) => {
    //     form_values[field] = "";
    //   });
    //   creativeFields.forEach((field) => {
    //     form_values[field] = "";
    //   });

    //   form_values.is_creative_prize = "no";
    // }
    if (form_values.is_creative_prize == "yes") {
      growFields.forEach((field) => {
        form_values[field] = "";
      });
      // breakthroughFields.forEach((field) => {
      //   form_values[field] = "";
      // });
    }
    if (form_values.is_creative_prize == "no") {
      // breakthroughFields.forEach((field) => {
      //   form_values[field] = "";
      // });
      creativeFields.forEach((field) => {
        form_values[field] = "";
      });
    }

    setLoading(true);
    let status = currentStep + 1;

    if (values.is_creative_prize == "yes") {
      status = 29;
    }

    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });

      goToStep(status);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleSelectChange = (field, value) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const fetchCodes = async () => {
    const { data } = await http.get(`${config.api_url}general/countries/codes`);

    if (data) {
      let items = data.map((item) => {
        return {
          value: `+${item.phone_code.replace(/[^a-zA-Z0-9 ]/g, "")}`,
          label: `+${item.phone_code.replace(/[^a-zA-Z0-9 ]/g, "")}`,
        };
      });

      setCountryCode(items);
    }
  };

  const fetchCountries = async () => {
    const { data } = await http.get(`${config.api_url}general/countries`);

    if (data) {
      let items = data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setCountry(items);
    }
  };

  const fetchStates = async (id) => {
    const { data } = await http.get(`${config.api_url}general/states/${id}`);

    if (data) {
      let items = data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setState(items);
    }
  };
  const fetchCity = async (id) => {
    const { data } = await http.get(`${config.api_url}general/cities/${id}`);

    if (data) {
      let items = data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setCity(items);
    }
  };

  useEffect(() => {
    fetchCodes();
    fetchCountries();
    fetchStates(User.data.country || 101);
    fetchCity(User.data.state || 4008);

    console.log(values?.country);
  }, []);

  let StateRef = null;
  let CityRef = null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper">
              <input
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                className="form_input"
                placeholder="First Name*"
              />
              {formError.first_name && (
                <p className="error_message">{formError.first_name}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <input
                type="text"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                className="form_input"
                placeholder="Last Name*"
              />
              {formError.last_name && (
                <p className="error_message">{formError.last_name}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <input
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                className="form_input"
                placeholder="Title/Designation*"
              />
              {formError.title && (
                <p className="error_message">{formError.title}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <input
                type="text"
                name="comp_name"
                value={values.comp_name}
                onChange={handleChange}
                className="form_input"
                placeholder="Company Name*"
              />
              {formError.comp_name && (
                <p className="error_message">{formError.comp_name}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <Select
                defaultValue={
                  User?.data?.country
                    ? {
                        value: values?.country,
                        label: User?.data?.countryData?.[0]?.name || "India",
                      }
                    : {
                        value: 101,
                        label: "India",
                      }
                }
                onChange={({ value }) => {
                  setCity([]);
                  fetchStates(value);
                  handleSelectChange("country", value);
                  setValues((prevState) => {
                    return {
                      ...prevState,
                      state: "",
                      city: "",
                    };
                  });
                  StateRef?.clearValue();
                  CityRef?.clearValue();
                }}
                options={country}
                className="select_box_wrapper"
                classNamePrefix="select_box_el"
                placeholder={
                  <div className="select-placeholder-text">Country*</div>
                }
                styles={colourStyles}
                autoComplete="off"
              />
              <img
                src={faqClose}
                alt=""
                className="inputdownarrow"
                loading="lazy"
              />
              {formError.country && (
                <p className="error_message">{formError.country}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}

            <div className="form_field_wrapper">
              <textarea
                className="form_input textarea_input"
                placeholder="Street Address 1*"
                name="address1"
                value={values.address1}
                onChange={handleChange}
              ></textarea>
              {formError.address1 && (
                <p className="error_message">{formError.address1}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <textarea
                className="form_input textarea_input"
                placeholder="Street Address 2"
                name="address2"
                value={values.address2}
                onChange={handleChange}
              ></textarea>
              {formError.address2 && (
                <p className="error_message">{formError.address2}</p>
              )}
            </div>
            {state.length != 0 && (
              <div className="form_field_wrapper">
                <Select
                  ref={(ref) => {
                    StateRef = ref;
                  }}
                  styles={colourStyles}
                  defaultValue={
                    User?.data?.state
                      ? {
                          value: values.state,
                          label: User?.data?.stateData[0]?.name,
                        }
                      : null
                  }
                  onChange={(value) => {
                    if (value) {
                      fetchCity(value.value);
                      handleSelectChange("state", value.value);
                      CityRef?.clearValue();
                    }
                  }}
                  options={state}
                  className="select_box_wrapper"
                  classNamePrefix="select_box_el"
                  placeholder={
                    <div className="select-placeholder-text">STATE</div>
                  }
                  autoComplete="off"
                />
                <img src={faqClose} alt="" className="inputdownarrow" />
                {formError.state && (
                  <p className="error_message">{formError.state}</p>
                )}
              </div>
            )}
            {city.length != 0 && state.length != 0 && (
              <div className="form_field_wrapper">
                <Select
                  ref={(ref) => {
                    CityRef = ref;
                  }}
                  styles={colourStyles}
                  defaultValue={
                    User?.data?.city
                      ? {
                          value: values.city,
                          label: User?.data?.cityData[0]?.name,
                        }
                      : null
                  }
                  onChange={(value) => {
                    if (value) {
                      handleSelectChange("city", value.value);
                    }
                  }}
                  options={city}
                  className="select_box_wrapper"
                  classNamePrefix="select_box_el"
                  placeholder={
                    <div className="select-placeholder-text">TOWN/CITY</div>
                  }
                  autoComplete="off"
                />
                <img src={faqClose} alt="" className="inputdownarrow" />
                {formError.city && (
                  <p className="error_message">{formError.city}</p>
                )}
              </div>
            )}

            <div className="form_field_wrapper">
              <input
                type="text"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                className="form_input"
                placeholder="Post Code*"
              />
              {formError.zip && (
                <p className="error_message">{formError.zip}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <div className="contact_no_flex">
                <div className="contact_left">
                  <Select
                    styles={colourStyles}
                    defaultValue={{
                      // value: values.country_code,
                      // label: values.country_code,
                      value: "+91",
                      label: "+91",
                    }}
                    onChange={({ value }) => {
                      handleSelectChange("country_code", value);
                    }}
                    options={countryCode}
                    className="select_box_wrapper"
                    classNamePrefix="select_box_el"
                    placeholder={
                      <div className="select-placeholder-text">Code*</div>
                    }
                    autoComplete="off"
                  />
                  <img src={faqClose} alt="" className="inputdownarrow" />
                </div>
                <div className="contact_right">
                  <input
                    type="tel"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Phone Number*"
                  />
                  {formError.phone && (
                    <p className="error_message">{formError.phone}</p>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`form_field_wrapper form_field_wrapper_full_width first_question_box ${
                values.is_university_student === "" && "question_box"
              } ${values.is_university_student === "no" && "question_box"} ${
                values.is_university_student === "yes" &&
                "form_field_wrapper_margin_zero"
              }`}
            >
              <p className="section_subdesc">Are you a university student?*</p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="universityStudentYes"
                    value="yes"
                    className="checkox_input"
                    name="is_university_student"
                    checked={values.is_university_student === "yes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="universityStudentYes"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="universityStudentNo"
                    value="no"
                    className="checkox_input"
                    name="is_university_student"
                    checked={values.is_university_student === "no"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="universityStudentNo"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.is_university_student && (
                <p className="error_message error_zero_padding">
                  {formError.is_university_student}
                </p>
              )}
            </div>
            {values.is_university_student === "yes" && (
              <>
                <div className="form_field_wrapper first_question_box question_box">
                  <input
                    type="text"
                    name="university_name"
                    value={values.university_name}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Please provide University Name*"
                  />
                  {formError.university_name && (
                    <p className="error_message">{formError.university_name}</p>
                  )}
                </div>
              </>
            )}
            {/* <div
              className={`form_field_wrapper form_field_wrapper_full_width ${
                values.is_breakthrough_prize !== "no" &&
                "form_field_wrapper_margin_zero"
              } ${values.is_breakthrough_prize === "no" && "question_box"}`}
            >
              <p className="section_subdesc">
                Is this a submission for the Breakthrough Prize (active
                ingredient innovation)?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="breakthrough"
                    value="yes"
                    className="checkox_input"
                    name="is_breakthrough_prize"
                    checked={values.is_breakthrough_prize === "yes"}
                    onChange={handleChange}
                  />
                  <label htmlFor="breakthrough" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="breakthroughNo"
                    value="no"
                    className="checkox_input"
                    name="is_breakthrough_prize"
                    checked={values.is_breakthrough_prize === "no"}
                    onChange={handleChange}
                  />
                  <label htmlFor="breakthroughNo" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.is_breakthrough_prize && (
                <p className="error_message error_zero_padding">
                  {formError.is_breakthrough_prize}
                </p>
              )}
            </div> */}

            <div className="form_field_wrapper form_field_wrapper_full_width form_field_wrapper_margin_zero">
              <p className="section_subdesc">
                Is this a submission for the CREATE Prize (eligible applicants
                include photographers, filmmakers and set designers who are
                submitting existing work)*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="creative"
                    value="yes"
                    className="checkox_input"
                    name="is_creative_prize"
                    checked={values.is_creative_prize === "yes"}
                    onChange={handleChange}
                  />
                  <label htmlFor="creative" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="creativeNo"
                    value="no"
                    className="checkox_input"
                    name="is_creative_prize"
                    checked={values.is_creative_prize === "no"}
                    onChange={handleChange}
                  />
                  <label htmlFor="creativeNo" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>
                  </label>
                </div>
              </div>
              {formError.is_creative_prize && (
                <p className="error_message error_zero_padding">
                  {formError.is_creative_prize}
                </p>
              )}
            </div>

            <div className="form_field_wrapper">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  type="submit"
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
