import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import { accountLogin } from "../../helpers/constant-words";

const LogoutText = () => {
  const navigate = useNavigate();

  const handlelogout = () => {
    console.log(config.jwt_auth_key);
    localStorage.removeItem(config.jwt_auth_key);
    localStorage.removeItem(config.jwt_remember_me);

    navigate(accountLogin, { replace: true });
  };

  return (
    <>
      <p className="logout_text">
        If you wish to logout, please{" "}
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handlelogout}
        >
          CLICK HERE
        </span>{" "}
        to automatically SAVE AND EXIT.
      </p>
    </>
  );
};

export default LogoutText;
