import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const Step27 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const User = useContext(userContext);
  const [values, setValues] = useState({
    market_win_reason: User.data.market_win_reason,
    extra_info: User.data.extra_info,
    breakthrough_in_currently_market:
      User.data.breakthrough_in_currently_market,
    estee_discussion: User.data.estee_discussion,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setValues({
      market_win_reason: User.data.market_win_reason,
      extra_info: User.data.extra_info,
      breakthrough_in_currently_market:
        User.data.breakthrough_in_currently_market,
      estee_discussion: User.data.estee_discussion,
    });
  }, [currentStep]);
  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      market_win_reason: Joi.string().trim().required().label("This"),
      breakthrough_in_currently_market: Joi.string()
        .trim()
        .required()
        .label("This"),
      estee_discussion: Joi.string().trim().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.market_win_reason) {
      if (!checkWordLen(values.market_win_reason, 100)) {
        errors["market_win_reason"] = `only 100 words is allowed`;
      }
    }
    if (values.extra_info) {
      if (!checkWordLen(values.extra_info, 100)) {
        errors["extra_info"] = `only 100 words is allowed`;
      }
    }

    return errors ? errors : null;
  };

  function checkWordLen(text, length) {
    var len = text.split(/[\s]+/);

    if (len.length > length) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 19} totalSteps={9} />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper">
              <p className="section_subdesc extended">
                Why will you win the market (100 words or less)?*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="market_win_reason"
                value={values.market_win_reason}
                onChange={handleChange}
              ></textarea>
              {formError.market_win_reason && (
                <p className="error_message">{formError.market_win_reason}</p>
              )}
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}

            <div className="form_field_wrapper">
              <p className="section_subdesc extended">
                Anything else you’d like us to know (100 words or less)?*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="extra_info"
                value={values.extra_info}
                onChange={handleChange}
              ></textarea>
              {formError.extra_info && (
                <p className="error_message">{formError.extra_info}</p>
              )}
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}

            <div className="form_field_wrapper">
              <p className="section_subdesc word_break">
                Are you currently in market?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="breakthrough_in_currently_market_yes"
                    value="Yes"
                    className="checkox_input"
                    name="breakthrough_in_currently_market"
                    checked={values.breakthrough_in_currently_market === "Yes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="breakthrough_in_currently_market_yes"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="breakthrough_in_currently_market_no"
                    value="No"
                    className="checkox_input"
                    name="breakthrough_in_currently_market"
                    checked={values.breakthrough_in_currently_market === "No"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="breakthrough_in_currently_market_no"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.breakthrough_in_currently_market && (
                <p className="error_message error_zero_padding">
                  {formError.breakthrough_in_currently_market}
                </p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper">
              <p className="section_subdesc word_break">
                Are you currently in discussions with The Estée Lauder Companies
                on your active ingredient?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="estee_discussion_yes"
                    value="Yes"
                    className="checkox_input"
                    name="estee_discussion"
                    checked={values.estee_discussion === "Yes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="estee_discussion_yes"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="estee_discussion_no"
                    value="No"
                    className="checkox_input"
                    name="estee_discussion"
                    checked={values.estee_discussion === "No"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="estee_discussion_no"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.estee_discussion && (
                <p className="error_message error_zero_padding">
                  {formError.estee_discussion}
                </p>
              )}
            </div>

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step27;
