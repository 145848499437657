import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const Step16 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();
  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      elevator_pitch: Joi.string().trim().required().label("This"),
      brand_desc: Joi.string().trim().required().label("This"),
      brand_exp: Joi.string().trim().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.elevator_pitch) {
      if (!checkWordLen(values.elevator_pitch, 25)) {
        errors["elevator_pitch"] = `Only 25 words is allowed`;
      }
    }
    if (values.brand_desc) {
      if (!checkWordLen(values.brand_desc, 100)) {
        errors["brand_desc"] = `only 100 words is allowed`;
      }
    }
    if (values.brand_exp) {
      if (!checkWordLen(values.brand_exp, 100)) {
        errors["brand_exp"] = `only 100 words is allowed`;
      }
    }

    return errors ? errors : null;
  };

  function checkWordLen(text, length) {
    var len = text.split(/[\s]+/);

    if (len.length > length) {
      // alert(
      //   "You cannot put more than " + wordLen + " words in this text area."
      // );
      // oldValue = text != oldValue ? text : oldValue;
      // text = oldValue ? oldValue : "";
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    setValues({
      elevator_pitch: User.data.elevator_pitch,
      brand_desc: User.data.brand_desc,
      brand_exp: User.data.brand_exp,
    });
  }, [currentStep]);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep - 8}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper question_box">
              <p className="section_subdesc extended">
                Please provide your company 'elevator pitch' in 25 words or
                less.*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="elevator_pitch"
                value={values.elevator_pitch}
                onChange={handleChange}
              ></textarea>
              {formError.elevator_pitch && (
                <p className="error_message">{formError.elevator_pitch}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper question_box"></div>
            ) : null}
            <div className="form_field_wrapper question_box">
              <p className="section_subdesc extended">
                This year, finalists may be invited to create an immersive
                beauty experience (e.g., provide a spa service or makeover with
                your product). Please describe your proposed brand experience
                you would like to build.*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="brand_exp"
                value={values.brand_exp}
                onChange={handleChange}
              ></textarea>
              {formError.brand_exp && (
                <p className="error_message">{formError.brand_exp}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper form_field_wrapper_margin_zero"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_margin_zero">
              <p className="section_subdesc extended">
                Describe your brand and business in 100 words or less.*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="brand_desc"
                value={values.brand_desc}
                onChange={handleChange}
              ></textarea>
              {formError.brand_desc && (
                <p className="error_message">{formError.brand_desc}</p>
              )}
            </div>

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step16;
