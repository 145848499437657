import { Navigate } from "react-router-dom";
import { accountLogin, masterClassLogin } from "../../helpers/constant-words";
// import { useAuth } from "../../context/AuthContext";
import helper from "../../helpers/helper";

export const ProtectedRoute = ({ children, guard }) => {
  let user = helper.getUser();

  if (!user) {
    // user is not authenticated
    return (
      <Navigate to={guard == "masterclass" ? masterClassLogin : accountLogin} />
    );
  }
  return children;
};
