import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./RegisterForm.scss";
import StepWizard from "react-step-wizard";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Step1 from "./FormSteps/Step1";
import Step2 from "./FormSteps/Step2";
import Step3 from "./FormSteps/Step3";
import Step4 from "./FormSteps/Step4";
import Step5 from "./FormSteps/Step5";
import Step6 from "./FormSteps/Step6";
import Step7 from "./FormSteps/Step7";
import Step8 from "./FormSteps/Step8";
import Step9 from "./FormSteps/Step9";
import Step10 from "./FormSteps/Step10";
import Step11 from "./FormSteps/Step11";
import Step12 from "./FormSteps/Step12";
import Step13 from "./FormSteps/Step13";
import Step14 from "./FormSteps/Step14";
import Step15 from "./FormSteps/Step15";
import Step16 from "./FormSteps/Step16";
import Step17 from "./FormSteps/Step17";
import Step18 from "./FormSteps/Step18";
import Step19 from "./FormSteps/Step19";
import Step20 from "./FormSteps/Step20";
import helper from "../../helpers/helper";
import UserContext from "../../context/User/UserContext";
import http from "../../helpers/http";
import config from "../../config/config";
import { accountLogin, ThankyouURL } from "../../helpers/constant-words";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Step21 from "./FormSteps/Step21";
import Step22 from "./FormSteps/Step22";
import Step23 from "./FormSteps/Step23";
import Step24 from "./FormSteps/Step24";
import Step25 from "./FormSteps/Step25";
import Step26 from "./FormSteps/Step26";
import Step27 from "./FormSteps/Step27";
import Step28 from "./FormSteps/Step28";
import Step29 from "./FormSteps/Step29";
import Step30 from "./FormSteps/Step30";
import Step31 from "./FormSteps/Step31";
import Step32 from "./FormSteps/Step32";
import Step33 from "./FormSteps/Step33";
import ApplicationPixel from "../../components/ApplicationPixel/ApplicationPixel";

const RegisterForm = () => {
  const User = useContext(UserContext);
  const navigate = useNavigate();
  const currentUser = helper.getUser();
  useMemo(() => {
    if (!currentUser) {
      toast.error(
        "You have been logged out of the application system. Your information has been saved. Please re-login to continue.",
        {
          toastId: 123,
          onClose: () => navigate(accountLogin, { replace: true }),
          className: "logout-toast",
        }
      );
    }
  }, [currentUser]);
  const [formStep, setformStep] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchUser = async (id) => {
    const { data } = await http.get(
      `${config.api_url}2024/account/details/${id}`
    );
   
    if (data) {
      if (data?.status === "registered") {
        setformStep(1);
      } else if (data?.status === "completed") {
        navigate(ThankyouURL);
      } else {
        setformStep(parseInt(data.status));
      }

      User.update(data);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchUser(currentUser?._id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [formStep]);

  if (loading) {
    return null;
  }

  return (
    <>
    <ApplicationPixel/>
      <Header />
      <main>
        <section className="registerformsec">
          <div className="my_container">
            {/* <UserState> */}
            <StepWizard
              transitions={{
                enterRight: "formChangeAnimation",
                enterLeft: "formChangeAnimation",
                intro: "formChangeAnimation",
              }}
              initialStep={formStep}
              // isLazyMount={true}
            >
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 />
              <Step6 />
              <Step7 />
              <Step8 />
              <Step9 />
              <Step10 />
              <Step11 />
              <Step12 />
              <Step13 />
              <Step14 />
              <Step15 />
              <Step16 />
              <Step17 />
              <Step18 />
              <Step19 />
              <Step20 />
              <Step21 />
              <Step22 />
              <Step23 />
              <Step24 />
              <Step25 />
              <Step26 />
              <Step27 />
              <Step28 />

              {/* CREATE steps */}
              <Step29 />
              <Step30 />
              <Step31 />
              <Step32 />
              <Step33 />
            </StepWizard>
            {/* </UserState> */}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default RegisterForm;
