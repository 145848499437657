import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const Step32 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();
  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setValues({
      work_summary: User.data.work_summary,
      creative_background: User.data.creative_background,
      extra_info: User.data.extra_info,
    });
  }, [currentStep]);
  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      work_summary: Joi.string().trim().required().label("This"),
      creative_background: Joi.string().trim().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.work_summary) {
      if (!checkWordLen(values.work_summary, 100)) {
        errors["work_summary"] = `only 100 words is allowed`;
      }
    }
    if (values.creative_background) {
      if (!checkWordLen(values.creative_background, 100)) {
        errors["creative_background"] = `only 100 words is allowed`;
      }
    }
    if (values.extra_info) {
      if (!checkWordLen(values.extra_info, 100)) {
        errors["extra_info"] = `only 100 words is allowed`;
      }
    }

    return errors ? errors : null;
  };

  function checkWordLen(text, length) {
    var len = text.split(/[\s]+/);

    if (len.length > length) {
      // alert(
      //   "You cannot put more than " + wordLen + " words in this text area."
      // );
      // oldValue = text != oldValue ? text : oldValue;
      // text = oldValue ? oldValue : "";
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 27} totalSteps={6} />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper question_box">
              <p className="section_subdesc extended">
                Please provide a descriptive summary of your work that you have
                submitted (less than 100 words)*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="work_summary"
                value={values.work_summary}
                onChange={handleChange}
              ></textarea>
              {formError.work_summary && (
                <p className="error_message">{formError.work_summary}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper question_box">
              <p className="section_subdesc extended">
                Describe your background and your experience in this creative
                space (100 words or less)*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="creative_background"
                value={values.creative_background}
                onChange={handleChange}
              ></textarea>
              {formError.creative_background && (
                <p className="error_message">{formError.creative_background}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_margin_zero">
              <p className="section_subdesc extended">
                Anything else you’d like us to know about you or your work. (100
                words or less)?
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="extra_info"
                value={values.extra_info}
                onChange={handleChange}
              ></textarea>
              {formError.extra_info && (
                <p className="error_message">{formError.extra_info}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step32;
