import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import "./login.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import {
  accountRegister,
  accountReset,
  accountSignUp,
} from "../../helpers/constant-words";
import { images } from "../../images";
import { useWindowSize } from "react-use";
import OtpInput from "react-otp-input";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import _ from "lodash";
import { Helmet } from "react-helmet";
import ApplicationPixel from "../../components/ApplicationPixel/ApplicationPixel";

const Login = () => {
  const { width: windowWidth } = useWindowSize();
  const [otpVal, setOtpVal] = useState("");
  const [otploading, setotpLoading] = useState(false);
  const [otpresend, setotpresend] = useState(false);
  const [visibility, setvisibility] = useState({
    form: true,
    otp: false,
  });

  const handleotpChange = (otp) => {
    setOtpVal(otp);
  };

  const [values, setValues] = useState({
    email: "",
    password: "",
    remember_me: false,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "2024/account/login";

  const navigate = useNavigate();

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),

    password: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const data = await http.post(base_url, values);

    if (data) {
      // const token = data.data.token;
      // localStorage.setItem(config.jwt_auth_key, token);
      // navigate(accountRegister);

      setvisibility({
        form: false,
        otp: true,
      });

      // window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    console.log(e.target.type);

    if (e.target.type == "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]:
            e.target.name == "email"
              ? e.target.value.toLowerCase()
              : e.target.value,
        };
      });
    }
  };

  const handleotpverify = async (e) => {
    setLoading(true);
    // let form_values = _.omit(values, ["password"]);
    const data = await http.post(base_url + "/otp/verify", {
      ...values,
      otp: otpVal,
    });

    if (data) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_auth_key, token);
      localStorage.setItem(config.jwt_remember_me, values.remember_me);
      navigate(accountRegister);
      // window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handlresendotp = async (e) => {
    setotpLoading(true);
    const { data } = await http.post(base_url, values);

    if (data) {
      setotpresend(true);
      setOtpVal("");
      // hiding the message after 5 sec
      setTimeout(() => {
        setotpresend(false);
      }, 5000);
    }

    setotpLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visibility]);

  return (
    <>
      <ApplicationPixel />

      <Header />
      {visibility?.form && (
        <section className={`login_sec1 ${styles.login_sec1}`}>
          <div className={`row ${styles.row}`}>
            <div className="col-md-6 p-0">
              {windowWidth > 767 ? (
                <img
                  width={720}
                  height={824}
                  src={images.login.image}
                  alt={images.login.alt}
                  className={styles.login_img}
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 p-0">
              <div className={styles.form_container}>
                <div
                  className={`section_subheading text-center ${styles.section_subheading}`}
                >
                  Log in
                </div>
                <form onSubmit={handleSubmit}>
                  <div
                    className={`form_field_wrapper ${styles.form_field_wrapper}`}
                  >
                    <input
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      className={`form_input ${styles.form_input}`}
                      placeholder="Email*"
                    />
                    {formError.email && (
                      <p className="error_message">{formError.email}</p>
                    )}
                  </div>
                  <div
                    className={`form_field_wrapper ${styles.form_field_wrapper}`}
                  >
                    <input
                      type="password"
                      className={`form_input ${styles.form_input}`}
                      placeholder="Password*"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    {formError.password && (
                      <p className="error_message">{formError.password}</p>
                    )}
                  </div>
                  <div className={styles.checkbox_wrapper}>
                    {/* <div className={`checkbox-div2 ${styles.checkbox_div2}`}>
                      <input
                        type="checkbox"
                        id="remember_me"
                        name="remember_me"
                        checked={values.remember_me}
                        onChange={handleChange}
                        value={true}
                      />
                      <label for="remember_me" className={styles.checkbox_desc}>
                        KEEP ME LOGGED IN
                      </label>
                    </div> */}
                    <div className="checkbox_input_wrapper">
                      <input
                        type="checkbox"
                        id="remember_me"
                        name="remember_me"
                        className="checkox_input"
                        checked={values.remember_me}
                        onChange={handleChange}
                        value={true}
                      />
                      <label for="remember_me" className="checkbox_label">
                        <span className="checkbox_outer">
                          <span className="checkbox_right_tick"></span>
                        </span>
                        <span className="label_text">KEEP ME LOGGED IN</span>{" "}
                      </label>
                    </div>
                    <div className={styles.forgot_pass}>
                      <Link to={accountReset}>Forgot password?</Link>
                    </div>
                  </div>
                  <div className={styles.btn_wrapper}>
                    <button
                      type="submit"
                      className={`project-button form_cta ${styles.submit_btn}`}
                    >
                      {loading ? "Loading.." : "Log in"}
                    </button>
                  </div>
                  <div className={styles.register_text}>
                    Not registered yet?{" "}
                    <Link to={accountSignUp}>Create an Account</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      {visibility?.otp && (
        <section className={styles.otp_sec}>
          <div className={styles.content_wrapper}>
            <h2
              className={`section_subheading text-center ${styles.section_subheading}`}
            >
              Enter OTP
            </h2>
            <p className={`section_subdesc ${styles.otp_inbox_msg}`}>
              Please check your inbox for the OTP to validate your account.
            </p>
            <p className={`section_subdesc ${styles.inbox_notice_msg}`}>
              Do not close this page. Please check your inbox for the OTP to
              validate your account. If you do not see it, please check your
              spam folder. Any issues? Please contact us at{" "}
              <a href="mailto:applications@beautyandyouawards.com">
                applications@beautyandyouawards.com
              </a>
              .
            </p>
            <OtpInput
              value={otpVal}
              onChange={handleotpChange}
              numInputs={5}
              containerStyle={styles.otp_input_wrapper}
              inputStyle={styles.otp_input}
              isInputNum={true}
              focusStyle={styles.otp_focused}
              shouldAutoFocus
            />
            <button
              className={`project-button form_cta ${styles.otp_verify_btn}`}
              disabled={otpVal.length >= 5 ? false : true}
              onClick={handleotpverify}
            >
              VERIFY
            </button>
            <p
              className={`section_subdesc ${styles.resend_otp_text}`}
              onClick={handlresendotp}
            >
              {otploading ? "Sending..." : "RESEND OTP"}
            </p>
            {otpresend && (
              <p className={`section_subdesc ${styles.otp_sent_text}`}>
                OTP is sent to registered email id
              </p>
            )}

            {/* {otperror && (
                <p className="section_desc otp_sent_text">Invalid OTP</p>
              )} */}
          </div>
        </section>
      )}

      <Footer />
    </>
  );
};

export default Login;
