import React, { useEffect, useState } from "react";
import "./PasswordReset.scss";
import OtpInput from "react-otp-input";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Joi from "joi";
import http from "../../helpers/http";
import config from "../../config/config";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { masterClassLogin, accountLogin } from "../../helpers/constant-words";

const PasswordReset = (props) => {
  const [searchParams] = useSearchParams();
  const [otpVal, setOtpVal] = useState("");

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpresend, setotpresend] = useState(false);
  const [otploading, setotpLoading] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [visibility, setvisibility] = useState({
    form: true,
    otp: false,
    reset: false,
  });

  let base_url = config.api_url + "2024/account";
  const navigate = useNavigate();

  const handleotpChange = (otp) => {
    console.log("otp:-", otp);
    setOtpVal(otp);
  };

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const resetschema = Joi.object({
    password: Joi.string().alphanum().min(8).required(),
    // .messages({
    //   "string.min": `Password must be {#limit} alphanumeric characters`,
    //   "string.max": `Password must be {#limit} alphanumeric characters`,
    // }),

    confirm_password: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("confirm password")
      .messages({ "any.only": "password does not match" }),
  }).options({ allowUnknown: true });

  const resetvalidate = async (data) => {
    const { error } = resetschema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const data = await http.post(base_url + "/forgot", values);

    if (data) {
      setvisibility({
        form: false,
        otp: true,
        reset: false,
      });
      // setValues({ email: "" });
      // redirect
      // setValues({ email: "" });
      // setisMsgVisible(true);
    }
    setLoading(false);
  };
  const handleReset = async (e) => {
    e.preventDefault();
    let errors = await resetvalidate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const data = await http.post(base_url + "/reset", values);

    if (data) {
      // redirect
      // setValues({ email: "" });
      // setisMsgVisible(true);
      if (searchParams.get("s") == "masterclass") {
        navigate(masterClassLogin);
      } else {
        navigate(accountLogin);
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleotpverify = async (e) => {
    setLoading(true);
    // let form_values = _.omit(values, ["confirm_password"]);
    const data = await http.post(base_url + "/forgot/otp/verify", {
      otp: otpVal,
    });

    if (data) {
      setvisibility({
        form: false,
        otp: false,
        reset: true,
      });
    }
    setLoading(false);
  };

  const handlresendotp = async (e) => {
    setotpLoading(true);
    const data = await http.post(base_url + "/forgot", values);

    if (data) {
      setotpresend(true);
      setOtpVal("");
    }
    setotpLoading(false);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="forgot_password_main_wrapper">
        {visibility.form && (
          <section className="forgot_password_sec">
            <h1 className="section_subheading text-center">Forgot Password</h1>
            <p className="section_subdesc forgot_desc">
              Applicants should be founders, CEOs or Presidents of their
              respective companies.
            </p>
            <div className="content_wrapper">
              <form onSubmit={handleSubmit}>
                <div className="form_field_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {formError.email && (
                    <p className="error_message">{formError.email}</p>
                  )}
                </div>

                <div className="btn_wrapper">
                  <button className="project-button form_cta" type="submit">
                    {loading ? "Loading.." : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </section>
        )}
        {visibility.otp && (
          <section className="otp_sec reset_otp">
            <div className="content_wrapper">
              <h2 className="section_subheading text-center">Enter OTP</h2>
              <p className="section_subdesc otp_inbox_msg">
                Please check your inbox for the OTP to validate your account.
              </p>
              <p className="section_subdesc inbox_notice_msg">
                Do not close this page. Please check your inbox for the OTP to
                validate your account. If you do not see it, please check your
                spam folder. Any issues? Please contact us at{" "}
                <a href="mailto:applications@beautyandyouawards.com">
                  applications@beautyandyouawards.com
                </a>
                .
              </p>
              <OtpInput
                value={otpVal}
                onChange={handleotpChange}
                numInputs={5}
                containerStyle="otp_input_wrapper"
                inputStyle="otp_input"
                isInputNum={true}
                focusStyle="otp_focused"
                shouldAutoFocus
              />
              <div className="btn_wrapper">
                <button
                  className="project-button form_cta otp_verify_btn"
                  disabled={otpVal.length >= 5 ? false : true}
                  onClick={handleotpverify}
                >
                  VERIFY
                </button>
              </div>
              <p
                className="section_desc resend_otp_text"
                onClick={handlresendotp}
              >
                {otploading ? "Sending..." : "RESEND OTP"}
              </p>
              {otpresend && (
                <p className="otp_sent_text">
                  OTP is sent to registered email id
                </p>
              )}
            </div>
          </section>
        )}
        {visibility.reset && (
          <section className="reset_password_sec">
            <h1 className="section_subheading reset_pass_heading">
              Reset Password
            </h1>

            <div className="content_wrapper">
              <form onSubmit={handleReset}>
                <div className="form_field_wrapper">
                  <input
                    type="password"
                    className="form_input"
                    placeholder="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {formError.password && (
                    <p className="error_message">{formError.password}</p>
                  )}
                </div>
                <div className="form_field_wrapper">
                  <input
                    type="password"
                    className="form_input"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                  />
                  {formError.confirm_password && (
                    <p className="error_message">
                      {formError.confirm_password}
                    </p>
                  )}
                </div>

                <div className="btn_wrapper">
                  <button className="project-button form_cta">
                    {loading ? "Loading.." : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </section>
        )}
      </main>
      <Footer />
    </>
  );
};

export default PasswordReset;
