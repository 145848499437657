import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const productCategories = [
  {
    categoryName: "Formulation",
  },

  {
    categoryName: "Packaging",
  },
  {
    categoryName: "Shipping",
  },
  {
    categoryName: "Diversity & Inclusion",
  },
  {
    categoryName: "Income Inequality",
  },
  {
    categoryName: "Local Communities",
  },
  {
    categoryName: "Human Rights",
  },
  {
    categoryName: "Other",
  },
];

let selectedProductCategories;
const Step9 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const [checkProductCategoryState, setCheckProductCategoryState] = useState(
    []
  );
  const [susCateOther, setSusCateOther] = useState(false);

  const handleProductCategoryCheckboxChange = (position) => {
    selectedProductCategories = [];
    const updatedCheckProductCategoryState = checkProductCategoryState.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckProductCategoryState(updatedCheckProductCategoryState);

    updatedCheckProductCategoryState.forEach((item, index) => {
      if (item) {
        selectedProductCategories.push(
          productCategories[index]["categoryName"]
        );
      }
    });

    setSusCateOther(selectedProductCategories.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["sustain_focus_category"]: selectedProductCategories.join(","),
      };
    });
  };

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      sustain_focus_category: User.data.sustain_focus_category,
      other_sustain_focus_category: User.data.other_sustain_focus_category,
      is_sustain_mission: User.data.is_sustain_mission,
      // target_age: User.data.target_age,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      is_sustain_mission: Joi.string().required().label("This"),
    };

    if (data.is_sustain_mission == "Yes") {
      schemaObj.sustain_focus_category = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    if (data.sustain_focus_category.split(",").includes("Other")) {
      schemaObj.other_sustain_focus_category = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    let form_values = values;

    if (form_values.is_sustain_mission == "No") {
      form_values.other_sustain_focus_category = "";
      form_values.sustain_focus_category = "";
      setCheckProductCategoryState(
        new Array(productCategories.length).fill(false)
      );
      setSusCateOther(false);
    }

    if (!form_values.sustain_focus_category.split(",").includes("Other")) {
      form_values.other_sustain_focus_category = "";
    }

    setLoading(true);
    let status = currentStep + 1;
    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    let dbCategory = User.data.sustain_focus_category.split(",");

    let dbSelCat = productCategories.map(({ categoryName }) => {
      return dbCategory.includes(categoryName);
    });

    setCheckProductCategoryState(dbSelCat);

    setSusCateOther(dbCategory.includes("Other"));
  }, [currentStep]);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div
              className={`form_field_wrapper form_field_wrapper_margin_zero ${
                values.is_sustain_mission === "no" &&
                "question_box"
              } ${values.is_sustain_mission === "Yes" && "question_box"}`}
            >
              <p className="section_subdesc word_break">
                Do you have a sustainability or social impact mission?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="sustainabilityYes"
                    value="Yes"
                    className="checkox_input"
                    name="is_sustain_mission"
                    checked={values.is_sustain_mission === "Yes"}
                    onChange={handleChange}
                  />
                  <label htmlFor="sustainabilityYes" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="sustainabilityNo"
                    value="No"
                    className="checkox_input"
                    name="is_sustain_mission"
                    checked={values.is_sustain_mission === "No"}
                    onChange={handleChange}
                  />
                  <label htmlFor="sustainabilityNo" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.is_sustain_mission && (
                <p className="error_message error_zero_padding">
                  {formError.is_sustain_mission}
                </p>
              )}
            </div>
            {values.is_sustain_mission === "Yes" && (
              <>
                {windowWidth >= 767 ? (
                  <div className="form_field_wrapper"></div>
                ) : null}
                <div
                  className={`form_field_wrapper form_field_wrapper_full_width ${
                    susCateOther ? "" : "form_field_wrapper_margin_zero"
                  }`}
                >
                  <p className="section_subdesc word_break">
                    In which categories are you focusing (please select all that
                    apply)?*
                  </p>
                  <div className="radio_row_flex">
                    {productCategories.map(({ categoryName }, index) => (
                      <div className="checkbox_input_wrapper" key={index}>
                        <input
                          type="checkbox"
                          id={`susCate-checkbox-${index}`}
                          value={categoryName}
                          name={categoryName}
                          className="checkox_input"
                          checked={checkProductCategoryState[index]}
                          onChange={() =>
                            handleProductCategoryCheckboxChange(index)
                          }
                        />
                        <label
                          htmlFor={`susCate-checkbox-${index}`}
                          className="checkbox_label"
                        >
                          <span className="checkbox_outer">
                            <span className="checkbox_right_tick"></span>
                          </span>
                          <span className="label_text">{categoryName}</span>{" "}
                        </label>
                      </div>
                    ))}
                  </div>
                  {/* <div className="checkbox_input_wrapper">
                    <input
                      type="checkbox"
                      id="susCateOther"
                      value="Other"
                      name="Other"
                      className="checkox_input"
                      checked={susCateOther}
                      onChange={() => setSusCateOther(!susCateOther)}
                    />
                    <label htmlFor="susCateOther" className="checkbox_label">
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">Other</span>{" "}
                    </label>
                  </div> */}
                  {formError.sustain_focus_category && (
                    <p className="error_message error_zero_padding">
                      {formError.sustain_focus_category}
                    </p>
                  )}
                </div>
                {/* {windowWidth >= 767 ? (
                  <div className="form_field_wrapper"></div>
                ) : null} */}
                <div className="form_field_wrapper form_field_wrapper_margin_zero">
                  {susCateOther && (
                    <>
                      <input
                        type="text"
                        name="other_sustain_focus_category"
                        value={values.other_sustain_focus_category}
                        onChange={handleChange}
                        className="form_input"
                        placeholder="Type here"
                      />
                      {formError.other_sustain_focus_category && (
                        <p className="error_message">
                          {formError.other_sustain_focus_category}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step9;
