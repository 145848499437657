import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
import { programpolicypdf } from "../../../images";
import { Link } from "react-router-dom";
const Step6 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      buss_video_link: User.data.buss_video_link,
    });
  }, [currentStep]);
  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      buss_video_link: Joi.string().uri().required().label("Link"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_full_width form_field_wrapper_margin_zero file_upload_form_field social_text video_upload">
              <p className="section_subdesc adjust_width">
                Please upload a link to a 3-minute (max) video explaining your
                business. We want to hear your brand story and see your ideas.
                Be as creative as you wish! If you have any questions on what to
                submit, please consult the{" "}
                <Link
                  to={programpolicypdf}
                  target="_blank"
                  className="link_btn"
                >
                  Program Rules
                </Link>
                .*
              </p>
            </div>
            <div className="form_field_wrapper form_field_wrapper_margin_zero">
              <input
                type="text"
                name="buss_video_link"
                value={values.buss_video_link}
                onChange={handleChange}
                className="form_input"
                placeholder="Paste link here"
              />
              {formError.buss_video_link && (
                <p className="error_message">{formError.buss_video_link}</p>
              )}
              <br />
              <p className="section_subdesc ">
                YouTube or Vimeo links only please.
              </p>
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step6;
