import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";

import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";

const Step4 = ({ previousStep, currentStep, nextStep, goToStep }) => {
  const { width: windowWidth } = useWindowSize();
  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      in_currently_market: User.data.in_currently_market,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      in_currently_market: Joi.string().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);
    let status = values.in_currently_market == "yes" ? currentStep + 1 : 13;

    let form_values = values;

    if (form_values.in_currently_market == "yes") {
      form_values.mission = "";
      form_values.buss_overview = "";
    }
    if (form_values.in_currently_market == "no") {
      form_values.addressing_opp = "";
      form_values.revenue_2022 = "";
      form_values.revenue_india = "";
      form_values.investment_to_date = "";
      form_values.buss_overview = "";
    }

    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });
      goToStep(status);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_margin_zero">
              <p className="section_subdesc">
                Is your company currently trading in-market (i.e., are you
                currently selling your products via your own site or other
                retailers)?
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Yes"
                    value="yes"
                    className="checkox_input"
                    name="in_currently_market"
                    checked={values.in_currently_market === "yes"}
                    onChange={handleChange}
                  />
                  <label htmlFor="Yes" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="No"
                    value="no"
                    className="checkox_input"
                    name="in_currently_market"
                    checked={values.in_currently_market === "no"}
                    onChange={handleChange}
                  />
                  <label htmlFor="No" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              {formError.in_currently_market && (
                <p className="error_message error_zero_padding">
                  {formError.in_currently_market}
                </p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper form_field_wrapper_margin_zero"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step4;
