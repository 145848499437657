import React, { useState, useContext } from "react";
import "../RegisterForm.scss";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
import { programpolicypdf } from "../../../images";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
const Step30 = ({ previousStep, currentStep, nextStep, goToStep }) => {
  const User = useContext(userContext);
  const [values, setValues] = useState({
    // buss_overview_link: User.data.buss_overview_link,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const { width: windowWidth } = useWindowSize();
  let base_url =
    config.api_url + "2024/forms/registration/files/" + User.data._id;
  let sizeLimit = 20;
  const validate = async (data) => {
    let schemaObj = {};

    if (!User.data.buss_overview) {
      schemaObj.buss_overview = Joi.required().label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.buss_overview) {
      if (values.buss_overview.size > sizeLimit * 1024 * 1024) {
        errors["buss_overview"] = `File needs to be under ${sizeLimit}mb`;
      }
      let allowedExt = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-office",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-office",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedExt.includes(values.buss_overview.type)) {
        errors["buss_overview"] = "Please upload valid file type";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    let formData = new FormData();
    if (values.buss_overview) {
      formData.append("buss_overview", values.buss_overview);
    }

    formData.append("status", currentStep + 1);
    const { data } = await http.put(base_url, formData);

    if (data) {
      User.update({ ...User.data, ...data, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "file") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 27} totalSteps={6} />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_full_width form_field_wrapper_margin_zero file_upload_form_field">
              <p className="section_subdesc adjust_width">
                Please upload an example of your creative work that speaks to
                the following creative brief.
                <br />
                <br />
                The CREATE prize was created to showcase the leading creative
                talent (e.g., photographers, filmmakers, set designers, makeup
                artists, etc.) who are visually defining the future of beauty
                for Indian consumers globally.
                <br />
                <br />
                We are seeking applicants who are inspired by India’s rich
                culture of age-old traditions alongside breakthrough
                innovations. Applicants can show work or propose a new creative
                concept from any creative field (e.g., photography, films,
                spaces) to show their vision of what beauty will look like and
                where beauty is going in the next 20 years.
                <br />
                <br />
                Submissions may include original photography, illustrations, and
                other creative work. If you have any questions on what to
                submit, please consult the{" "}
                <Link
                  to={programpolicypdf}
                  target="_blank"
                  className="link_btn"
                >
                  Program Rules
                </Link>
                .*
              </p>
              <div className="select_wrapper">
                <div className="select_flex">
                  <div className="select_left">
                    <input
                      type="file"
                      id="creativeuploadFile"
                      hidden
                      name="buss_overview"
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="creativeuploadFile"
                      className="upload_label"
                    >
                      <span className="">Select file</span>
                    </label>
                  </div>
                  <div className="select_right">
                    <p
                      className={`section_subdesc ${
                        windowWidth <= 600 ? "text-left" : ""
                      }`}
                    >
                      {values?.buss_overview?.name ||
                        User?.data?.buss_overview ||
                        "No file selected"}
                    </p>
                  </div>
                </div>
                <p className="section_subdesc mt-2 mt-lg-1 message_text">
                  {`pdf/doc/ppt - max file size ${sizeLimit}mb`}
                </p>
              </div>
              {formError.buss_overview && (
                <p className="mt-1 error_message error_zero_padding">
                  {formError.buss_overview}
                </p>
              )}
            </div>

            {/* <div className="form_field_wrapper form_field_wrapper_full_width full_text">
              <p className="section_subdesc">
                If you have a dropbox link instead, please add link below.
              </p>
            </div>

            <div className="form_field_wrapper">
              <input
                type="text"
                name="buss_overview_link"
                value={values.buss_overview_link}
                onChange={handleChange}
                className="form_input"
                placeholder="Paste link here"
              />
              {formError.buss_overview_link &&
              <p className="error_message">{formError.buss_overview_link}</p> }
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step30;
