import React, { useState, useContext } from "react";
import "../RegisterForm.scss";
import "../RegisterTalha.scss";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import {
  privacyandcookiepolicyroute,
  ThankyouURL,
} from "../../../helpers/constant-words";
import { Link } from "react-router-dom";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
import { programpolicypdf, termsandconditionsPDF } from "../../../images";
const Step33 = ({ currentStep, previousStep }) => {
  const navigate = useNavigate();
  const [term1, setTerm1] = useState(false);
  const handleterm1 = (e) => {
    setTerm1((current) => !current);
  };

  const User = useContext(userContext);
  const [values] = useState({
    // buss_video_link: User.data.buss_video_link,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      // buss_video_link: Joi.string().required().label("This"),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!term1) {
      errors["term1"] = "REQUIRED";
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: "completed",
    });

    if (data) {
      User.update({ ...User.data, ...values, status: "completed" });

      // nextStep();
      navigate(ThankyouURL);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 27} totalSteps={6} />
        <p className="section_subdesc warning_desc_wrapper">
          I have read and agree to the{" "}
          <Link
            to={privacyandcookiepolicyroute}
            target="_blank"
            className="link_btn"
          >
            Privacy Policy
          </Link>
          ,{" "}
          <Link to={programpolicypdf} target="_blank" className="link_btn">
            Program Rules
          </Link>
          , and{" "}
          <Link
            to={termsandconditionsPDF}
            target="_blank"
            rel="noreferrer"
            className="link_btn"
          >
            Terms and Conditions{" "}
          </Link>
          and confirm that all information provided by me in this application
          form is true and correct, that I have all necessary right and
          authority to submit this application and that by doing so I am not
          violating any intellectual property rights or any other rights of any
          third parties, including without limitation, any trade secret,
          copyright, authorship rights, trademark and/or personality rights
          (such as image rights or privacy rights). By submitting this
          application, I consent, on my behalf and, if applicable, on behalf of
          the entity I represent, to Estée Lauder International, Inc. (ELI), its
          affiliates and any party authorized by ELI to use, publicize, evaluate
          and distribute the information contained in this application,
          including all pictures, content, data and videos for any purpose ELI
          may deem fit and appropriate, and in all forms of media now known or
          hereafter invented.*
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="checkbox_wrapper2">
              {/* <div className="checkbox-div2">
                <input
                  type="checkbox"
                  name="term1"
                  checked={term1}
                  id="yesTerms3"
                  onClick={handleterm1}
                />
                <label for="yesTerms3" className="section_subdesc">
                  I confirm that I accept the{" "}
                  <Link
                    to={privacyandcookiepolicyroute}
                    target="_blank"
                    className="link_btn"
                  >
                    Privacy Policy
                  </Link>
                  ,{" "}
                  <Link
                    to={programpolicypdf}
                    target="_blank"
                    className="link_btn"
                  >
                    Program Rules
                  </Link>{" "}
                  and{" "}
                  <a
                    href={termsandconditionsPDF}
                    target="_blank"
                    className="link_btn"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  .
                </label>
                {formError.term1&&
                <p className="error_message">
                  <strong>{formError.term1}</strong>
                </p>}
              </div> */}
              <div className="confirmation_wrapper">
                <input
                  type="checkbox"
                  name="term1"
                  checked={term1}
                  id="yesTerms3"
                  onClick={handleterm1}
                  className="confirmation_input"
                />
                <label
                  htmlFor="yesTerms3"
                  className={`confirmation_label  ${
                    formError.term1 === "REQUIRED" ? "mb-0" : ""
                  }`}
                >
                  <span className="confirmation_outer">
                    <span className="confirmation_right_tick"></span>
                  </span>
                  <span className="confirmation_label_text">
                    I confirm that I accept the{" "}
                    <Link
                      to={privacyandcookiepolicyroute}
                      target="_blank"
                      className="link_btn"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </Link>
                    ,{" "}
                    <Link
                      to={programpolicypdf}
                      target="_blank"
                      className="link_btn"
                      rel="noreferrer"
                    >
                      Program Rules
                    </Link>{" "}
                    and{" "}
                    <a
                      href={termsandconditionsPDF}
                      target="_blank"
                      rel="noreferrer"
                      className="link_btn"
                    >
                      Terms and Conditions
                    </a>
                    .
                  </span>{" "}
                </label>
                {formError.term1 && (
                  <p
                    className={`error_message  ${
                      formError.term1 === "REQUIRED" ? "mt-2 mb-3" : ""
                    }`}
                  >
                    <strong>{formError.term1}</strong>
                  </p>
                )}
              </div>
              <p className="section_subdesc warning_desc_wrapper">
                Once you click finalize and submit, you will not be able to make
                any changes or edits to your application. Please ensure this is
                your final application.
              </p>
            </div>

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button className="project-button form_cta black-filled">
                  {loading ? "FINALIZING.." : "FINALIZE AND SUBMIT"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step33;
