import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const distributionChannelsArr = [
  {
    categoryName: "Own Brand Websites",
  },
  {
    categoryName: "Own Brand Stores",
  },
  {
    categoryName: "Nykaa",
  },
  {
    categoryName: "Multi-Brand Beauty Stores",
  },
  {
    categoryName: "Pharmacies",
  },
  {
    categoryName: "Online Marketplaces",
  },
  {
    categoryName: "Doctor Offices/Clinics",
  },
  {
    categoryName: "Spas/Salons",
  },
  {
    categoryName: "Tira Beauty",
  },
  {
    categoryName: "Shoppers Stop/SS Beauty",
  },
  {
    categoryName: "Amazon",
  },
  {
    categoryName: "Myntra",
  },
  {
    categoryName: "Other",
  },
];

const hearSourceArr = [
  {
    platform: "BEAUTY&YOU Instagram",
  },
  {
    platform: "Masterclasses",
  },
  {
    platform: "Judges",
  },
  {
    platform: "Influencers",
  },
  {
    platform: "Nykaa",
  },
  {
    platform: "Vogue",
  },
  {
    platform: "Business of Fashion",
  },
  {
    platform: "Economic Times",
  },
  {
    platform: "Vogue Business",
  },
  {
    platform: "Friends",
  },
  {
    platform: "Previous year winners",
  },
  {
    platform: "Other",
  },
];

const revenueArr = [
  {
    revenue: "Under 30 Lakhs",
    revenueName: "Under30Lakhs",
  },
  {
    revenue: "31-100 Lakhs",
    revenueName: "31-100Lakhs",
  },
  {
    revenue: "101-250 Lakhs",
    revenueName: "101-250Lakhs",
  },
  {
    revenue: "251-500 Lakhs",
    revenueName: "251-500Lakhs",
  },
  {
    revenue: "501-1000 Lakhs",
    revenueName: "501-1000Lakhs",
  },
  {
    revenue: "1001-5000 Lakhs",
    revenueName: "1001-5000Lakhs",
  },
  {
    revenue: "5001-10,000 Lakhs",
    revenueName: "5001-10,000Lakhs",
  },
  {
    revenue: "10,001 - 15,000 Lakhs",
    revenueName: "10,001-15,000Lakhs",
  },
  {
    revenue: "Over 15,000 Lakhs",
    revenueName: "Over15,000Lakhs",
  },
];

const investmentArr = [
  {
    investmentRange: "Self-Funded",
  },
  {
    investmentRange: "Under 30 Lakhs",
  },
  {
    investmentRange: "31-100 Lakhs",
  },
  {
    investmentRange: "101 - 250 Lakhs",
  },
  {
    investmentRange: "251-500 Lakhs",
  },
  {
    investmentRange: "501-1000 Lakhs",
  },
  {
    investmentRange: "1001-5000 Lakhs",
  },
  {
    investmentRange: "5001-10,000 Lakhs",
  },
  {
    investmentRange: "10,001-15,000 Lakhs",
  },
  {
    investmentRange: "Over 15,000 Lakhs",
  },
];

let selectedChannelCategories,selectedSourceCategories;
const Step10 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const [checkChannelCategoryState, setCheckChannelCategoryState] = useState(
    []
  );
  const [checkHearSourceState, setCheckHearSourceState] = useState(
    []
  );
  const [susCateOther, setSusCateOther] = useState(false);
  const [hearSourceOther, setHearSourceOther] = useState(false);
  const [platformOther, setPlatformOther] = useState(false);

  const handleChannelCategoryCheckboxChange = (position) => {
    selectedChannelCategories = [];
    const updatedCheckChannelCategoryState = checkChannelCategoryState.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckChannelCategoryState(updatedCheckChannelCategoryState);

    updatedCheckChannelCategoryState.forEach((item, index) => {
      if (item) {
        selectedChannelCategories.push(
          distributionChannelsArr[index]["categoryName"]
        );
      }
    });

    setSusCateOther(selectedChannelCategories.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["distribution_channel"]: selectedChannelCategories.join(","),
      };
    });
  };
  const handleHearSourceCheckboxChange = (position) => {

    console.log("source changed")
    selectedSourceCategories = [];
    const updatedCheckHearSourceState = checkHearSourceState.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckHearSourceState(updatedCheckHearSourceState);

    updatedCheckHearSourceState.forEach((item, index) => {
      if (item) {
        selectedSourceCategories.push(
          hearSourceArr[index]["platform"]
        );
      }
    });

    setHearSourceOther(selectedSourceCategories.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["hear_source"]: selectedSourceCategories.join(","),
      };
    });
  };

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      distribution_channel: User.data.distribution_channel,
      other_distribution_channel: User.data.other_distribution_channel,
      hear_source: User.data.hear_source,
      other_hear_source: User.data.other_hear_source,
      revenue_2022: User.data.revenue_2022,
      revenue_india: User.data.revenue_india,
      traction_info: User.data.traction_info,
      investment_to_date: User.data.investment_to_date,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      distribution_channel: Joi.string().required().label("This"),
      hear_source: Joi.string().required().label("This"),
      revenue_2022: Joi.string().required().label("This"),
      investment_to_date: Joi.string().required().label("This"),
      revenue_india: Joi.number().allow("").label("This"),
    };

    if (data.distribution_channel.split(",").includes("Other")) {
      schemaObj.other_distribution_channel = Joi.string()
        .trim()
        .required()
        .label("This");
    }
    if (data.hear_source.split(",").includes("Other")) {
      schemaObj.other_hear_source = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.traction_info) {
      if (!checkWordLen(values.traction_info, 100)) {
        errors["traction_info"] = `only 100 words is allowed`;
      }
    }

    return errors ? errors : null;
  };

  function checkWordLen(text, length) {
    var len = text.split(/[\s]+/);

    if (len.length > length) {
      // alert(
      //   "You cannot put more than " + wordLen + " words in this text area."
      // );
      // oldValue = text != oldValue ? text : oldValue;
      // text = oldValue ? oldValue : "";
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    let form_values = values;

    if (!form_values.distribution_channel.split(",").includes("Other")) {
      form_values.other_distribution_channel = "";
    }
    if (!form_values.hear_source.split(",").includes("Other")) {
      form_values.other_hear_source = "";
    }

    setLoading(true);
    let status = currentStep + 1;
    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    let dbCategory = User.data.distribution_channel.split(",");

    let dbSelCat = distributionChannelsArr.map(({ categoryName }) => {
      return dbCategory.includes(categoryName);
    });

    setCheckChannelCategoryState(dbSelCat);
    setSusCateOther(dbCategory.includes("Other"));


    let sourceDbCategory = User.data.hear_source.split(",");

    let sourceDbSelCat = hearSourceArr.map(({ platform }) => {
      return sourceDbCategory.includes(platform);
    });

    setCheckHearSourceState(sourceDbSelCat);
    setHearSourceOther(sourceDbCategory.includes("Other"));
  }, [currentStep]);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_full_width question_box">
              <p className="section_subdesc word_break">
                What were your 2023 revenues?*
              </p>
              <div className="radio_row_flex">
                {revenueArr.map(({ revenue, revenueName }, i) => (
                  <div className="checkbox_input_wrapper" key={i}>
                    <input
                      type="radio"
                      id={revenueName}
                      value={revenue}
                      className="checkox_input"
                      name="revenue_2022"
                      checked={values.revenue_2022 === revenue}
                      onChange={handleChange}
                    />
                    <label htmlFor={revenueName} className="checkbox_label">
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{revenue}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {formError.revenue_2022 && (
                <p className="error_message error_zero_padding">
                  {formError.revenue_2022}
                </p>
              )}
            </div>
            {/* {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <p className="section_subdesc">
                How much of your company revenue comes from India?
              </p>
            </div>
            <div className="form_field_wrapper question_box">
              <input
                type="tel"
                name="revenue_india"
                value={values.revenue_india}
                onChange={handleChange}
                className="form_input"
                placeholder="%"
              />
              {formError.revenue_india && (
                <p className="error_message">{formError.revenue_india}</p>
              )}
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div
              className={`form_field_wrapper form_field_wrapper_full_width ${
                !susCateOther && " question_box"
              } `}
            >
              <p className="section_subdesc">
                What are your distribution channels? If you have not launched
                yet, what do you anticipate will be your distribution channels?
                Please select all that apply.*
              </p>
              <div className="radio_row_flex">
                {distributionChannelsArr.map(({ categoryName }, index) => (
                  <div className="checkbox_input_wrapper" key={index}>
                    <input
                      type="checkbox"
                      id={`distribution-checkbox-${index}`}
                      value={categoryName}
                      name={categoryName}
                      className="checkox_input"
                      checked={checkChannelCategoryState[index]}
                      onChange={() =>
                        handleChannelCategoryCheckboxChange(index)
                      }
                    />
                    <label
                      htmlFor={`distribution-checkbox-${index}`}
                      className="checkbox_label"
                    >
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{categoryName}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {/* <div className="checkbox_input_wrapper">
                <input
                  type="checkbox"
                  id="susCateOther"
                  value="Other"
                  name="Other"
                  className="checkox_input"
                  checked={susCateOther}
                  onChange={() => setSusCateOther(!susCateOther)}
                />
                <label htmlFor="susCateOther" className="checkbox_label">
                  <span className="checkbox_outer">
                    <span className="checkbox_right_tick"></span>
                  </span>
                  <span className="label_text">Other</span>{" "}
                </label>
              </div> */}
              {formError.distribution_channel && (
                <p className="error_message error_zero_padding">
                  {formError.distribution_channel}
                </p>
              )}
            </div>

            {susCateOther && (
              <div className="form_field_wrapper question_box">
                <>
                  <input
                    type="text"
                    name="other_distribution_channel"
                    value={values.other_distribution_channel}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Type here"
                  />
                  {formError.other_distribution_channel && (
                    <p className="error_message">
                      {formError.other_distribution_channel}
                    </p>
                  )}
                </>
              </div>
            )}

            <div
              className={`form_field_wrapper form_field_wrapper_full_width ${
                !hearSourceOther && " question_box"
              } `}
            >
              <p className="section_subdesc">
              How did you hear about BEAUTY&YOU?*
              </p>
              <div className="radio_row_flex">
                {hearSourceArr.map(({ platform }, index) => (
                  <div className="checkbox_input_wrapper" key={index}>
                    <input
                      type="checkbox"
                      id={`hearabout-checkbox-${index}`}
                      value={platform}
                      name={platform}
                      className="checkox_input"
                      checked={checkHearSourceState[index]}
                      onChange={() =>
                        handleHearSourceCheckboxChange(index)
                      }
                    />
                    <label
                      htmlFor={`hearabout-checkbox-${index}`}
                      className="checkbox_label"
                    >
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{platform}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {formError.hear_source && (
                <p className="error_message error_zero_padding">
                  {formError.hear_source}
                </p>
              )}
            </div>

            {hearSourceOther && (
              <div className="form_field_wrapper question_box">
                <>
                  <input
                    type="text"
                    name="other_hear_source"
                    value={values.other_hear_source}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Type here"
                  />
                  {formError.other_hear_source && (
                    <p className="error_message">
                      {formError.other_hear_source}
                    </p>
                  )}
                </>
              </div>
            )}

            {windowWidth >= 767 ? (
              <>{hearSourceOther && <div className="form_field_wrapper"></div>}</>
            ) : null}
            <div className="form_field_wrapper question_box">
              <p className="section_subdesc extended">
                Do you have any information around interest (pre-launch) or
                traction (in market) that you would like to share with us e.g.,
                revenue, LTV, pre-orders, or other interesting data points (100
                words or less)?
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="traction_info"
                value={values.traction_info}
                onChange={handleChange}
              ></textarea>
              {formError.traction_info && (
                <p className="error_message">{formError.traction_info}</p>
              )}
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_full_width form_field_wrapper_margin_zero">
              <p className="section_subdesc word_break">Investment to date*</p>
              <div className="radio_row_flex">
                {investmentArr.map(({ investmentRange }, i) => (
                  <div className="checkbox_input_wrapper" key={i}>
                    <input
                      type="radio"
                      id={investmentRange}
                      value={investmentRange}
                      className="checkox_input"
                      name="investment_to_date"
                      checked={values.investment_to_date === investmentRange}
                      onChange={handleChange}
                    />
                    <label htmlFor={investmentRange} className="checkbox_label">
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{investmentRange}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {formError.investment_to_date && (
                <p className="error_message error_zero_padding">
                  {formError.investment_to_date}
                </p>
              )}
            </div>

            {/* {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step10;
