import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
import { dirtColor, pinkColor } from "../../../helpers/constant-colors";

const productCategories = [
  {
    categoryName: "Skin Care",
  },

  {
    categoryName: "Body Care",
  },
  {
    categoryName: "Hair Care",
  },
  {
    categoryName: "Makeup",
  },
  {
    categoryName: "Fragrance",
  },
  {
    categoryName: "Home Fragrance",
  },
  {
    categoryName: "Mens",
  },
  {
    categoryName: "Other",
  },
];
const ageTargetRange = [
  {
    ageRange: "Under 18",
  },

  {
    ageRange: "19-25",
  },
  {
    ageRange: "26 - 40",
  },
  {
    ageRange: "41-59",
  },
  {
    ageRange: "60+",
  },
  {
    ageRange: "Other",
  },
];
var selectedProductCategories, selectAgeTarget;
const Step15 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();
  const [checkProductCategoryState, setCheckProductCategoryState] = useState(
    []
  );
  const [checkAgeTargetState, setCheckAgeTargetState] = useState([]);
  const [proCateOther, setProCateOther] = useState(false);
  const [ageTargetOther, setAgeTargetOther] = useState(false);

  const handleProductCategoryCheckboxChange = (position) => {
    selectedProductCategories = [];
    const updatedCheckProductCategoryState = checkProductCategoryState.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckProductCategoryState(updatedCheckProductCategoryState);

    updatedCheckProductCategoryState.forEach((item, index) => {
      if (item) {
        selectedProductCategories.push(
          productCategories[index]["categoryName"]
        );
      }
    });

    setProCateOther(selectedProductCategories.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["selling_product_category"]: selectedProductCategories.join(","),
      };
    });
  };
  const handleAgeTargetCheckboxChange = (position) => {
    selectAgeTarget = [];
    const updatedAgeTargetState = checkAgeTargetState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckAgeTargetState(updatedAgeTargetState);

    updatedAgeTargetState.forEach((item, index) => {
      if (item) {
        selectAgeTarget.push(ageTargetRange[index]["ageRange"]);
      }
    });

    setAgeTargetOther(selectAgeTarget.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["target_age"]: selectAgeTarget.join(","),
      };
    });
  };

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      selling_product_category: User.data.selling_product_category,
      target_age: User.data.target_age,
      other_selling_product_category: User.data.other_selling_product_category,
      other_target_age: User.data.other_target_age,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      selling_product_category: Joi.string().required().label("This"),
      target_age: Joi.string().required().label("This"),
    };

    if (data.selling_product_category.split(",").includes("Other")) {
      schemaObj.other_selling_product_category = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    if (data.target_age.split(",").includes("Other")) {
      schemaObj.other_target_age = Joi.string().trim().required().label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    let form_values = values;

    if (!form_values.selling_product_category.split(",").includes("Other")) {
      form_values.other_selling_product_category = "";
    }

    if (!form_values.target_age.split(",").includes("Other")) {
      form_values.other_target_age = "";
    }

    setLoading(true);
    let status = currentStep + 1;
    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    let dbCategory = User.data.selling_product_category.split(",");
    let dbSelCat = productCategories.map(({ categoryName }) => {
      return dbCategory.includes(categoryName);
    });

    setCheckProductCategoryState(dbSelCat);

    setProCateOther(dbCategory.includes("Other"));

    let dbAge = User.data.target_age.split(",");
    let dbSelAge = ageTargetRange.map(({ ageRange }) => {
      return dbAge.includes(ageRange);
    });

    setCheckAgeTargetState(dbSelAge);

    setAgeTargetOther(dbAge.includes("Other"));
  }, [currentStep]);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep - 8}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div
              className={`form_field_wrapper form_field_wrapper_full_width ${
                !proCateOther && "question_box"
              }`}
            >
              <p className="section_subdesc word_break">
                In which product categories do you sell or plan to sell (please
                select all that apply)?*
              </p>
              <div className="radio_row_flex">
                {productCategories.map(({ categoryName }, index) => (
                  <div className="checkbox_input_wrapper" key={index}>
                    <input
                      type="checkbox"
                      id={`noproductCat-checkbox-${index}`}
                      value={categoryName}
                      name={categoryName}
                      className="checkox_input"
                      checked={checkProductCategoryState[index]}
                      onChange={() =>
                        handleProductCategoryCheckboxChange(index)
                      }
                    />
                    <label
                      htmlFor={`noproductCat-checkbox-${index}`}
                      className="checkbox_label"
                    >
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{categoryName}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {/* <div className="checkbox_input_wrapper">
                <input
                  type="checkbox"
                  id="proCateOther"
                  value="Other"
                  name="Other"
                  className="checkox_input"
                  checked={proCateOther}
                  onChange={() => setProCateOther(!proCateOther)}
                />
                <label htmlFor="proCateOther" className="checkbox_label">
                  <span className="checkbox_outer">
                    <span className="checkbox_right_tick"></span>
                  </span>
                  <span className="label_text">Other</span>{" "}
                </label>
              </div> */}
              {formError.selling_product_category && (
                <p className="error_message error_zero_padding">
                  {formError.selling_product_category}
                </p>
              )}
            </div>
            {/* {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}
            {/* <div
              className={`form_field_wrapper ${
                !proCateOther ? "form_field_wrapper_margin_zero" : null
              } `}
            > */}
            {proCateOther && (
              <div className="form_field_wrapper question_box">
                <>
                  <input
                    type="text"
                    name="other_selling_product_category"
                    value={values.other_selling_product_category}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Type here"
                  />
                  {formError.other_selling_product_category && (
                    <p className="error_message">
                      {formError.other_selling_product_category}
                    </p>
                  )}
                </>
              </div>
            )}
            {/* </div> */}
            {windowWidth >= 767 ? (
              <>{proCateOther && <div className="form_field_wrapper"></div>}</>
            ) : null}
            <div className={`form_field_wrapper form_field_wrapper_full_width ${!ageTargetOther && "form_field_wrapper_margin_zero"}`}>
              <p className="section_subdesc word_break">
                What age demographic are you targeting?*
              </p>
              <div className="radio_row_flex">
                {ageTargetRange.map(({ ageRange }, index) => (
                  <div className="checkbox_input_wrapper" key={index}>
                    <input
                      type="checkbox"
                      id={`noage-checkbox-${index}`}
                      value={ageRange}
                      name={ageRange}
                      className="checkox_input"
                      checked={checkAgeTargetState[index]}
                      onChange={() => handleAgeTargetCheckboxChange(index)}
                    />
                    <label
                      htmlFor={`noage-checkbox-${index}`}
                      className="checkbox_label"
                    >
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{ageRange}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {/* <div className="checkbox_input_wrapper">
                <input
                  type="checkbox"
                  id="ageTargetOther"
                  value="Other"
                  name="Other"
                  className="checkox_input"
                  checked={ageTargetOther}
                  onChange={() => setAgeTargetOther(!ageTargetOther)}
                />
                <label htmlFor="ageTargetOther" className="checkbox_label">
                  <span className="checkbox_outer">
                    <span className="checkbox_right_tick"></span>
                  </span>
                  <span className="label_text">Other</span>{" "}
                </label>
              </div> */}
              {formError.target_age && (
                <p className="error_message error_zero_padding">
                  {formError.target_age}
                </p>
              )}
            </div>
            {/* {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}
            {ageTargetOther && (
              <div className="form_field_wrapper form_field_wrapper_margin_zero">
                <>
                  <input
                    type="text"
                    name="other_target_age"
                    value={values.other_target_age}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Type here"
                  />
                  {formError.other_target_age && (
                    <p className="error_message">
                      {formError.other_target_age}
                    </p>
                  )}
                </>
              </div>
            )}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step15;
