import React, { useEffect, useState } from "react";
import styles from "./signup.module.scss";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { accountRegister } from "../../helpers/constant-words";
import OtpInput from "react-otp-input";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import helper from "../../helpers/helper";
import _ from "lodash";
import ApplicationPixel from "../../components/ApplicationPixel/ApplicationPixel";
import { programpolicypdf, termsandconditionsPDF } from "../../images";

const SignUp = () => {
  const navigate = useNavigate();

  const [otpVal, setOtpVal] = useState("");
  const [term2, setTerm2] = useState(false);
  const [agree_to_email] = useState(false);
  const [agree_to_sms] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [otploading, setotpLoading] = useState(false);
  const [otpresend, setotpresend] = useState(false);
  const [visibility, setvisibility] = useState({
    form: true,
    otp: false,
    thank_you: false,
  });

  let base_url = config.api_url + "2024/account/signup";

  const handleotpChange = (otp) => {
    setOtpVal(otp);
  };

  const handleterm2 = (e) => {
    setTerm2((current) => !current);
  };

  const schema = Joi.object({
    first_name: Joi.string().required().label("first name"),
    last_name: Joi.string().required().label("last name"),

    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    password: Joi.string().alphanum().min(8).required(),
    confirm_password: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("confirm password")
      .messages({ "any.only": "password does not match" }),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!term2) {
      errors["term2"] = "REQUIRED";
    }

    // validating unique email
    if (data.email) {
      const result = await helper.validateEmail(data.email, "2024User");

      if (result === false) {
        errors["email"] = `This email is already in use.`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");
    console.log(values);
    e.preventDefault();
    let errors = await validate(values);
    console.log(errors);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url + "/otp", {
      email: values.email,
      name: values.first_name,
    });

    if (data) {
      setvisibility({
        form: false,
        otp: true,
        thank_you: false,
      });

      window.scrollTo(0, 0);
      // redirect
      // setValues({ email: "" });
      // setisMsgVisible(true);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name == "email"
            ? e.target.value.toLowerCase()
            : e.target.value,
      };
    });
  };
  const handleotpverify = async (e) => {
    setLoading(true);
    let form_values = _.omit(values, ["confirm_password"]);
    const data = await http.post(base_url + "/otp/verify", {
      ...form_values,
      otp: otpVal,
      status: "registered",
      agree_to_email,
      agree_to_sms,
    });

    if (data) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_auth_key, token);
      // setvisibility({
      //   form: false,
      //   otp: false,
      //   thank_you: true,
      // });
      navigate(accountRegister);

      window.scrollTo(0, 0);
      // redirect
      // setValues({ email: "" });
      // setisMsgVisible(true);
    }
    // else {
    //   setotperror(true);
    //   setTimeout(() => {
    //     setotperror(false);
    //   }, 5000);
    // }
    setLoading(false);
  };

  const handlresendotp = async (e) => {
    setotpLoading(true);
    const { data } = await http.post(base_url + "/otp", {
      email: values.email,
      name: values.first_name,
    });

    if (data) {
      setotpresend(true);
      setOtpVal("");
      // hiding the message after 5 sec
      setTimeout(() => {
        setotpresend(false);
      }, 5000);
    }

    setotpLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visibility]);

  return (
    <>
      <ApplicationPixel />
      <Header />
      {visibility?.form && (
        <section className={styles.signup_sec1}>
          <div className={`my_container ${styles.my_container}`}>
            <div className={styles.text_container}>
              <div className={`section_heading ${styles.section_heading}`}>
                Sign Up
              </div>
              <div className={`section_subdesc ${styles.section_subdesc}`}>
                Applicants should be founders, CEOs or Presidents of their
                respective companies.
              </div>
            </div>
            <div className={styles.form_container}>
              <form onSubmit={handleSubmit}>
                <div
                  className={`form_field_wrapper ${styles.form_field_wrapper}`}
                >
                  <input
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    className={`form_input ${styles.form_input}`}
                    placeholder="First Name*"
                  />
                  {formError.first_name && (
                    <p className="error_message">{formError.first_name}</p>
                  )}
                </div>
                <div
                  className={`form_field_wrapper ${styles.form_field_wrapper}`}
                >
                  <input
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    className={`form_input ${styles.form_input}`}
                    placeholder="Last Name*"
                  />
                  {formError.last_name && (
                    <p className="error_message">{formError.last_name}</p>
                  )}
                </div>
                <div
                  className={`form_field_wrapper ${styles.form_field_wrapper}`}
                >
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className={`form_input ${styles.form_input}`}
                    placeholder="Email*"
                  />
                  {formError.email && (
                    <p className="error_message">{formError.email}</p>
                  )}
                </div>
                <div
                  className={`form_field_wrapper ${styles.form_field_wrapper}`}
                >
                  <input
                    type="password"
                    className={`form_input ${styles.form_input}`}
                    placeholder="Password*"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {formError.password && (
                    <p className="error_message">{formError.password}</p>
                  )}
                </div>
                <div
                  className={`form_field_wrapper ${styles.form_field_wrapper}`}
                >
                  <input
                    type="password"
                    className={`form_input ${styles.form_input}`}
                    placeholder="Confirm Password*"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                  />{" "}
                  {formError.confirm_password && (
                    <p className="error_message">
                      {formError.confirm_password}
                    </p>
                  )}
                </div>
                <div className={styles.checkbox_wrapper}>
                  <div className={`checkbox-div2 ${styles.checkbox_div2}`}>
                    <input
                      type="checkbox"
                      id="checkbox2"
                      name="term2"
                      checked={term2}
                      onClick={handleterm2}
                    />
                    <label for="checkbox2" className={styles.checkbox_desc}>
                      By checking this box and clicking Sign Up, I agree to the{" "}
                      <Link
                        to={programpolicypdf}
                        target="_blank"
                        className={styles.link_btn}
                      >
                        Program Rules
                      </Link>
                      ,{" "}
                      <Link
                        to="/privacy-and-policy"
                        target="_blank"
                        className={styles.link_btn}
                      >
                        Privacy Policy
                      </Link>
                      , and{" "}
                      <Link
                        to={termsandconditionsPDF}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.link_btn}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and agree to receive BEAUTY&YOU emails and updates.
                    </label>
                    {formError.term2 && (
                      <p className="error_message">{formError.term2}</p>
                    )}
                  </div>
                </div>
                {/* <div className={styles.confirmation_wrapper}>
                  <input
                    type="checkbox"
                    id="checkbox2"
                    name="term2"
                    checked={term2}
                    onClick={handleterm2}
                    className={styles.confirmation_input}
                  />
                  <label
                    htmlFor="checkbox2"
                    className={` ${
                      formError.term2 === "REQUIRED" ? "mb-0" : ""
                    } ${styles.confirmation_label}`}
                  >
                    <span className={styles.confirmation_outer}>
                      <span className={styles.confirmation_right_tick}></span>
                    </span>
                    <span className={styles.confirmation_label_text}>
                      By checking this box and clicking Sign Up, I agree to the{" "}
                      <Link
                        to={termsandconditionsPDF}
                        target="_blank"
                        className={styles.link_btn}
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/privacy-and-policy"
                        target="_blank"
                        className={styles.link_btn}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </span>{" "}
                  </label>
                  <p
                    className={`error_message ${styles.error_message} ${
                      styles.confirmation_error
                    }  ${formError.term2 === "REQUIRED" ? "mt-2 mb-3" : ""}`}
                  >
                    <strong>{formError.term2}</strong>
                  </p>
                </div>
                <div className={styles.confirmation_wrapper}>
                  <input
                    type="checkbox"
                    id="checkbox3"
                    name="agree_to_email"
                    checked={agree_to_email}
                    onClick={() => {
                      set_agree_to_email((current) => !current);
                    }}
                    className={styles.confirmation_input}
                  />
                  <label
                    htmlFor="checkbox3"
                    className={` ${
                      formError.agree_to_email === "REQUIRED" ? "mb-0" : ""
                    } ${styles.confirmation_label}`}
                  >
                    <span
                      className={`${styles.confirmation_outer} ${styles.confirmation_outer2}`}
                    >
                      <span className={styles.confirmation_right_tick}></span>
                    </span>
                    <span className={styles.confirmation_label_text}>
                      I agree to receive newsletters by emails.
                    </span>
                  </label>
                  <p
                    className={`error_message ${styles.error_message} ${
                      styles.confirmation_error
                    }  ${
                      formError.agree_to_email === "REQUIRED" ? "mt-2 mb-3" : ""
                    }`}
                  >
                    <strong>{formError.agree_to_email}</strong>
                  </p>
                </div>
                <div className={styles.confirmation_wrapper}>
                  <input
                    type="checkbox"
                    id="checkbox4"
                    name="agree_to_sms"
                    checked={agree_to_sms}
                    onClick={() => {
                      set_agree_to_sms((current) => !current);
                    }}
                    className={styles.confirmation_input}
                  />
                  <label
                    htmlFor="checkbox4"
                    className={` ${
                      formError.agree_to_sms === "REQUIRED" ? "mb-0" : ""
                    } ${styles.confirmation_label}`}
                  >
                    <span
                      className={`${styles.confirmation_outer} ${styles.confirmation_outer3}`}
                    >
                      <span className={styles.confirmation_right_tick}></span>
                    </span>
                    <span className={styles.confirmation_label_text}>
                      I agree to receive newsletters by SMS.
                    </span>{" "}
                  </label>
                  <p
                    className={`error_message ${styles.error_message} ${
                      styles.confirmation_error
                    }  ${
                      formError.agree_to_sms === "REQUIRED" ? "mt-2 mb-3" : ""
                    }`}
                  >
                    <strong>{formError.agree_to_sms}</strong>
                  </p>
                </div> */}
                <div className={styles.btn_wrapper}>
                  <button
                    type="submit"
                    className={`project-button form_cta ${styles.submit_btn}`}
                  >
                    {loading ? "Loading.." : "Sign Up"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      )}
      {visibility?.otp && (
        <section className={styles.otp_sec}>
          <div className={styles.content_wrapper}>
            <h2
              className={`section_subheading text-center ${styles.section_subheading}`}
            >
              Enter OTP
            </h2>
            <p className={`section_subdesc ${styles.otp_inbox_msg}`}>
              Please check your inbox for the OTP to validate your account.
            </p>
            <p className={`section_subdesc ${styles.inbox_notice_msg}`}>
              Do not close this page. Please check your inbox for the OTP to
              validate your account. If you do not see it, please check your
              spam folder. Any issues? Please contact us at{" "}
              <a href="mailto:applications@beautyandyouawards.com">
                applications@beautyandyouawards.com
              </a>
              .
            </p>
            <OtpInput
              value={otpVal}
              onChange={handleotpChange}
              numInputs={5}
              containerStyle={styles.otp_input_wrapper}
              inputStyle={styles.otp_input}
              isInputNum={true}
              focusStyle={styles.otp_focused}
              shouldAutoFocus
            />
            <button
              className={`project-button   ${styles.otp_verify_btn}`}
              disabled={otpVal.length >= 5 ? false : true}
              onClick={handleotpverify}
            >
              VERIFY
            </button>
            <p
              className={`section_subdesc ${styles.resend_otp_text}`}
              onClick={handlresendotp}
            >
              {otploading ? "Sending..." : "RESEND OTP"}
            </p>
            {otpresend && (
              <p className={styles.otp_sent_text}>
                OTP is sent to registered email id
              </p>
            )}

            {/* {otperror && (
                <p className="section_desc otp_sent_text">Invalid OTP</p>
              )} */}
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default SignUp;
