import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";

import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
const Step3 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      buss_type: User.data.buss_type,
      gst_no: User.data.gst_no,
      emp_count: User.data.emp_count,
      buss_ref_name: User.data.buss_ref_name,
      buss_ref_designation: User.data.buss_ref_designation,
      buss_ref_email: User.data.buss_ref_email,
      comp_launch_date: User.data.comp_launch_date,
      comp_launch_month: User.data.comp_launch_month,
      comp_launch_year: User.data.comp_launch_year,
      // date_of_launch: moment().format("YYYY-MM-DD"),
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      buss_type: Joi.string().required().label("Bussiness type"),
      emp_count: Joi.string().required().label("Employee count"),
      buss_ref_name: Joi.string().required().label("Name"),
      // comp_launch_date: Joi.number().required().max(31).label("Date"),
      // comp_launch_month: Joi.number().required().max(12).label("Month"),
      // comp_launch_year: Joi.number().required().max(2024).label("Year"),
      buss_ref_email: Joi.string()
        .optional()
        .allow("")
        .email({ tlds: { allow: false } })
        .label("Email"),
    };

    if (
      data.buss_type == "Company" ||
      data.buss_type == "Partnership" ||
      data.buss_type == "Sole proprietorship"
    ) {
      // schemaObj.gst_no = Joi.string()
      //   .trim()
      //   .required()
      //   .min(15)
      //   .max(15)
      //   .label("GST no");

      schemaObj.comp_launch_date = Joi.number()
        .required()
        .max(31)
        .label("Date");
      schemaObj.comp_launch_month = Joi.number()
        .required()
        .max(12)
        .label("Month");
      schemaObj.comp_launch_year = Joi.number()
        .required()
        .max(2024)
        .label("Year");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    let form_values = values;

    if (form_values.buss_type == "Pre-Launch Concept") {
      form_values.gst_no = "";
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...form_values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo
          currentStep={currentStep}
          // totalSteps={props.totalSteps}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_full_width question_box">
              <p className="section_subdesc word_break">
                Are you a company/partnerships/sole proprietorship/pre-launch
                concept?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Company"
                    value="Company"
                    className="checkox_input"
                    name="buss_type"
                    checked={values.buss_type === "Company"}
                    onChange={handleChange}
                  />
                  <label htmlFor="Company" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Company</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Partnership"
                    value="Partnership"
                    className="checkox_input"
                    name="buss_type"
                    checked={values.buss_type === "Partnership"}
                    onChange={handleChange}
                  />
                  <label htmlFor="Partnership" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Partnership</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Sole proprietorship"
                    value="Sole proprietorship"
                    className="checkox_input"
                    name="buss_type"
                    checked={values.buss_type === "Sole proprietorship"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Sole proprietorship"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Sole proprietorship</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Pre-Launch Concept"
                    value="Pre-Launch Concept"
                    className="checkox_input"
                    name="buss_type"
                    checked={values.buss_type === "Pre-Launch Concept"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Pre-Launch Concept"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Pre-Launch Concept</span>{" "}
                  </label>
                </div>
              </div>
              {formError.buss_type && (
                <p className="error_message error_zero_padding">
                  {formError.buss_type}
                </p>
              )}
            </div>

            {(values.buss_type == "Company" ||
              values.buss_type == "Partnership" ||
              values.buss_type == "Sole proprietorship") && (
              <>
                <div className="form_field_wrapper question_box">
                  <p className="section_subdesc word_break extended">
                    Please provide GSTIN or Tax ID
                  </p>
                  <input
                    type="text"
                    name="gst_no"
                    value={values.gst_no}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="Please provide GSTIN or Tax ID"
                  />
                  {formError.gst_no && (
                    <p className="error_message">{formError.gst_no}</p>
                  )}
                </div>

                <div className="form_field_wrapper question_box">
                  <p className="section_subdesc extended word_break">
                    Date of company launch*
                  </p>
                  <div className="form_data_wrapper">
                    <input
                      type="text"
                      name="comp_launch_date"
                      value={values.comp_launch_date}
                      onChange={handleChange}
                      className="form_input company_date"
                      placeholder="Day"
                    />
                    <input
                      type="text"
                      name="comp_launch_month"
                      value={values.comp_launch_month}
                      onChange={handleChange}
                      className="form_input company_date"
                      placeholder="Month"
                    />
                    <input
                      type="text"
                      name="comp_launch_year"
                      value={values.comp_launch_year}
                      onChange={handleChange}
                      className="form_input company_date"
                      placeholder="Year"
                    />
                  </div>
                  {formError.comp_launch_date ||
                    formError.comp_launch_month ||
                    (formError.comp_launch_year && (
                      <p className="error_message">
                        {formError.comp_launch_date ||
                          formError.comp_launch_month ||
                          formError.comp_launch_year}
                      </p>
                    ))}
                </div>
              </>
            )}

            <div className="form_field_wrapper form_field_wrapper_full_width question_box">
              <p className="section_subdesc word_break">
                Number of Employees?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Under5"
                    value="Under 5"
                    className="checkox_input"
                    name="emp_count"
                    checked={values.emp_count === "Under 5"}
                    onChange={handleChange}
                  />
                  <label htmlFor="Under5" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Under 5</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="6-10"
                    value="6-10"
                    className="checkox_input"
                    name="emp_count"
                    checked={values.emp_count === "6-10"}
                    onChange={handleChange}
                  />
                  <label htmlFor="6-10" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">6-10</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="11-50"
                    value="11-50"
                    className="checkox_input"
                    name="emp_count"
                    checked={values.emp_count === "11-50"}
                    onChange={handleChange}
                  />
                  <label htmlFor="11-50" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">11-50</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="Over50"
                    value="Over 50"
                    className="checkox_input"
                    name="emp_count"
                    checked={values.emp_count === "Over 50"}
                    onChange={handleChange}
                  />
                  <label htmlFor="Over50" className="checkbox_label">
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Over 50</span>{" "}
                  </label>
                </div>
              </div>
              {formError.emp_count && (
                <p className="error_message error_zero_padding">
                  {formError.emp_count}
                </p>
              )}
            </div>
            <div className="form_field_wrapper form_field_wrapper_full_width form_field_wrapper_margin_zero">
              <p className="section_subdesc extended">Business Reference*</p>
            </div>

            <div className="form_field_wrapper">
              <input
                type="text"
                name="buss_ref_name"
                value={values.buss_ref_name}
                onChange={handleChange}
                className="form_input"
                placeholder="Name*"
              />
              {formError.buss_ref_name && (
                <p className="error_message">{formError.buss_ref_name}</p>
              )}
            </div>
            <div className="form_field_wrapper">
              <input
                type="text"
                name="buss_ref_designation"
                value={values.buss_ref_designation}
                onChange={handleChange}
                className="form_input"
                placeholder="Title / Designation"
              />
              {formError.buss_ref_designation && (
                <p className="error_message">
                  {formError.buss_ref_designation}
                </p>
              )}
            </div>
            <div className="form_field_wrapper form_field_wrapper_margin_zero">
              <input
                type="text"
                name="buss_ref_email"
                value={values.buss_ref_email}
                onChange={handleChange}
                className="form_input"
                placeholder="Email"
              />
              {formError.buss_ref_email && (
                <p className="error_message">{formError.buss_ref_email}</p>
              )}
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper form_field_wrapper_margin_zero"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step3;
