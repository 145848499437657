import React, { useContext, useEffect, useState } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import {
  fblogo,
  instalogo,
  linkedinlogo,
  snaplogo,
  tiktoklogo,
  ytlogo,
} from "../../../images";

import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";

const Step29 = ({ previousStep, currentStep, nextStep,goToStep }) => {
  const { width: windowWidth } = useWindowSize();

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      company_website: User.data.company_website,
      facebook: User.data.facebook,
      youtube: User.data.youtube,
      instagram: User.data.instagram,
      linkedin: User.data.linkedin,
      tiktok: User.data.tiktok,
      snapchat: User.data.snapchat,
      other_social: User.data.other_social,
    });
  }, [currentStep]);

  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const schema = Joi.object({}).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.put(base_url, {
      ...values,
      status: currentStep + 1,
    });

    if (data) {
      User.update({ ...User.data, ...values, status: currentStep + 1 });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="form_wrapper form_wrapper_step2">
        <RegisterFormStepNo
          currentStep={currentStep - 27}
          totalSteps={6}
        />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper">
              <input
                type="text"
                name="company_website"
                value={values.company_website}
                onChange={handleChange}
                className="form_input"
                placeholder="Company Website"
              />
            </div>
            <div className="form_field_wrapper form_field_wrapper_full_width first_question_box question_box">
              <p className="section_subdesc">
                Please provide social media handles that you believe are
                relevant to this application. <br /> This may include Instagram,
                Facebook, Youtube, TikTok, LinkedIn, Snapchat and others.
              </p>
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="instagram"
                value={values.instagram}
                onChange={handleChange}
                className="form_input"
                placeholder="Instagram"
              />
              <img src={instalogo} alt="" className="sociallogoimg" />
              {formError.instagram && (
                <p className="error_message">{formError.instagram}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="facebook"
                value={values.facebook}
                onChange={handleChange}
                className="form_input"
                placeholder="Facebook"
              />
              <img src={fblogo} alt="" className="sociallogoimg fblogo" />
              {formError.facebook && (
                <p className="error_message">{formError.facebook}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="youtube"
                value={values.youtube}
                onChange={handleChange}
                className="form_input"
                placeholder="Youtube"
              />
              <img src={ytlogo} alt="" className="sociallogoimg yt_icon" />
              {formError.youtube && (
                <p className="error_message">{formError.youtube}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="tiktok"
                value={values.tiktok}
                onChange={handleChange}
                className="form_input"
                placeholder="Tiktok"
              />
              <img src={tiktoklogo} alt="" className="sociallogoimg" />
              {formError.tiktok && (
                <p className="error_message">{formError.tiktok}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="linkedin"
                value={values.linkedin}
                onChange={handleChange}
                className="form_input"
                placeholder="Linkedin"
              />
              <img src={linkedinlogo} alt="" className="sociallogoimg" />
              {formError.linkedin && (
                <p className="error_message">{formError.linkedin}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon">
              <input
                type="text"
                name="snapchat"
                value={values.snapchat}
                onChange={handleChange}
                className="form_input"
                placeholder="Snapchat"
              />
              <img src={snaplogo} alt="" className="sociallogoimg snaplogo" />
              {formError.snapchat && (
                <p className="error_message">{formError.snapchat}</p>
              )}
            </div>
            <div className="form_field_wrapper form_with_icon form_field_wrapper_margin_zero">
              <input
                type="text"
                name="other_social"
                value={values.other_social}
                onChange={handleChange}
                className="form_input"
                placeholder="Other"
              />
              {formError.other_social && (
                <p className="error_message">{formError.other_social}</p>
              )}
            </div>

            {windowWidth >= 767 ? (
              <div className="form_field_wrapper form_field_wrapper_margin_zero"></div>
            ) : null}
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper btn_wrapper_margin">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    goToStep(1);
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step29;
