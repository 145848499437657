import React, { useEffect } from "react";
import "./thankyou.scss";
import { homepage } from "../../../helpers/constant-words";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import LogoutText from "../../../components/LogoutText/LogoutText";
import { Link } from "react-router-dom";
import ApplicationPixel from "../../../components/ApplicationPixel/ApplicationPixel";

const ImagineThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <ApplicationPixel />
      <section className="signupthankyousec">
        <div className="coming_content">
          <h1 className="section_subheading">
            Thank you for submitting your application to BEAUTY&YOU 2024.
          </h1>
          <p className="section_subdesc">
            Applications will close on August 29, 2024 at 11:59 PM IST. You will
            receive an email confirming your application. If you have any
            additional questions, please reach out to us at{" "}
            <a
              className="anchor"
              href="mailto:hello@beautyandyouawards.com"
            >
              hello@beautyandyouawards.com
            </a>
            .
          </p>
          {/* <LogoutText /> */}

          <div className="btn_wrapper">
            <Link
              to={homepage}
              className="project-button form_cta black-filled"
            >
              RETURN TO HOMEPAGE
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ImagineThankYou;
