import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import { useWindowSize } from "react-use";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";
import helper from "../../../helpers/helper";

const Step25 = ({ previousStep, currentStep, nextStep }) => {
  const { width: windowWidth } = useWindowSize();

  const User = useContext(userContext);
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      ingredient_market: User.data.ingredient_market,
      applicable_reason: User.data.applicable_reason,
      is_ingredient_sustain: User.data.is_ingredient_sustain,
      ingredient_sustain_reason: User.data.ingredient_sustain_reason,

      // target_age: User.data.target_age,
    });
  }, [currentStep]);
  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      ingredient_market: Joi.string().required().label("This"),
      applicable_reason: Joi.string().required().label("This"),
      is_ingredient_sustain: Joi.string().required().label("This"),
    };

    if (data.is_ingredient_sustain == "Yes") {
      schemaObj.ingredient_sustain_reason = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.ingredient_market) {
      if (!helper.checkWordLen(values.ingredient_market, 100)) {
        errors["ingredient_market"] = `only 100 words is allowed`;
      }
    }
    if (values.applicable_reason) {
      if (!helper.checkWordLen(values.applicable_reason, 100)) {
        errors["applicable_reason"] = `only 100 words is allowed`;
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    let form_values = values;

    if (form_values.is_ingredient_sustain == "No") {
      form_values.ingredient_sustain_reason = "";
    }

    setLoading(true);
    let status = currentStep + 1;
    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });

      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 19} totalSteps={9} />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper">
              <p className="section_subdesc extended">
                What is the market for your ingredient? (100 words or less).*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="ingredient_market"
                value={values.ingredient_market}
                onChange={handleChange}
              ></textarea>
              <p className="error_message">{formError.ingredient_market}</p>
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper">
              <p className="section_subdesc extended">
                Why is it applicable to Indian consumers? (100 words or less).*
              </p>
              <textarea
                className="form_input textarea_input"
                placeholder="Type here"
                name="applicable_reason"
                value={values.applicable_reason}
                onChange={handleChange}
              ></textarea>
              <p className="error_message">{formError.applicable_reason}</p>
            </div>
            {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null}
            <div className="form_field_wrapper">
              <p className="section_subdesc word_break">
                Is your ingredient considered sustainable?*
              </p>
              <div className="radio_row_flex">
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="is_ingredient_sustain_yes"
                    value="Yes"
                    className="checkox_input"
                    name="is_ingredient_sustain"
                    checked={values.is_ingredient_sustain === "Yes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="is_ingredient_sustain_yes"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">Yes</span>{" "}
                  </label>
                </div>
                <div className="checkbox_input_wrapper">
                  <input
                    type="radio"
                    id="is_ingredient_sustain_no"
                    value="No"
                    className="checkox_input"
                    name="is_ingredient_sustain"
                    checked={values.is_ingredient_sustain === "No"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="is_ingredient_sustain_no"
                    className="checkbox_label"
                  >
                    <span className="checkbox_outer">
                      <span className="checkbox_right_tick"></span>
                    </span>
                    <span className="label_text">No</span>{" "}
                  </label>
                </div>
              </div>
              <p className="error_message error_zero_padding">
                {formError.is_ingredient_sustain}
              </p>
            </div>
            {/* {values.is_sustain_mission === "Yes" && ( */}
            <>
              {windowWidth >= 767 ? (
                <div className="form_field_wrapper"></div>
              ) : null}
              {values.is_ingredient_sustain === "Yes" && (
                <>
                  <div className="form_field_wrapper form_field_wrapper_full_width">
                    <p className="section_subdesc word_break">
                      If yes, please describe why it is considered sustainable?*
                    </p>
                    {/* <p className="error_message error_zero_padding">
                  {formError.sustain_focus_category}
                </p> */}
                  </div>
                  <div className="form_field_wrapper">
                    {/* {susCateOther && ( */}
                    <>
                      <input
                        type="text"
                        name="ingredient_sustain_reason"
                        value={values.ingredient_sustain_reason}
                        onChange={handleChange}
                        className="form_input"
                        placeholder="Type here"
                      />
                      <p className="error_message">
                        {formError.ingredient_sustain_reason}
                      </p>
                    </>
                    {/* )} */}
                  </div>
                </>
              )}
            </>
            {/* )} */}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step25;
