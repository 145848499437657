import React, { useEffect } from "react";
import "./Homepage.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { images, newImages } from "../../images";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useWindowSize } from "react-use";
import {
  awardsURL,
  judges,
  masterClass,
  pressURL,
} from "../../helpers/constant-words";
import { Link } from "react-router-dom";
import { bannerData, mediaArr, quoteArr } from "../../data/homepageData";
import BannerContent from "../../components/BannerContent/BannerContent";

gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const { width: windowWidth } = useWindowSize();

  const bannerList = bannerData.map((item, i) => (
    <SwiperSlide>
      {item.type === "split" ? (
        <>
          {item?.grid === 1 ? (
            <div
              className="banner_content grid_banner"
              key={`split-banner-${i}`}
            >
              <div className="split_sec01">
                <img
                  width={724}
                  height={840}
                  src={windowWidth > 600 ? item.leftImg : item.mobLeftImg}
                  alt="banner 01 01"
                  className="left_img"
                />
                <BannerContent
                  bannerTitle={
                    windowWidth < 600
                      ? item.mobTitle
                        ? item.mobTitle
                        : item.title
                      : item.title
                  }
                  bannerDesc={item.desc}
                  ctaURL={item.ctaURL}
                  ctaText={item.ctaText}
                  featuredText={item.featuredText}
                  featuredImg={item.featuredImg}
                  titleIcon={item.titleIcon}
                  bannerClass={item?.class}
                />
              </div>
              {windowWidth > 991 && (
                <div className="split_sec02">
                  <div className="grid_container">
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                    <div className="grid_item">
                      <img
                        src={images.banner0702.image}
                        alt=""
                        className="judge_banner_img"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="banner_content split_banner"
              key={`split-banner-${i}`}
            >
              <div className="row g-0">
                <div className="col-lg-6 p-0 split_sec01">
                  <img
                    width={724}
                    height={840}
                    src={windowWidth > 600 ? item.leftImg : item.mobLeftImg}
                    alt="banner 01 01"
                    className="left_img"
                  />
                  <BannerContent
                    bannerTitle={
                      windowWidth < 600
                        ? item.mobTitle
                          ? item.mobTitle
                          : item.title
                        : item.title
                    }
                    bannerDesc={item.desc}
                    ctaURL={item.ctaURL}
                    ctaText={item.ctaText}
                    featuredText={item.featuredText}
                    featuredImg={item.featuredImg}
                    titleIcon={item.titleIcon}
                    bannerClass={item?.class}
                  />
                </div>
                {windowWidth > 991 && (
                  <div className="col-lg-6 p-0 split_sec02">
                    <img
                      width={723}
                      height={840}
                      src={item.rightImg}
                      alt="split banner second"
                      className="right_img"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="banner_content full_img_banner"
          key={`full-banner-${i}`}
        >
          {item.type === "img" ? (
            <img
              width={724}
              height={840}
              src={windowWidth > 600 ? item.bgImg : item.mobBgImg}
              alt="banner 01 01"
              className="fullsize_bg"
              style={{
                objectFit: item.size === "full" ? "cover" : "contain",
                objectPosition: windowWidth < 600 ? "top" : item.position,
              }}
            />
          ) : (
            <video className="video_banner" width="100%" autoPlay muted loop>
              <source
                className="video_source"
                src={windowWidth > 600 ? item.bgVideo : item.mobBgVideo}
                type="video/mp4"
              />
            </video>
          )}
          <BannerContent
            bannerTitle={
              windowWidth < 600
                ? item.mobTitle
                  ? item.mobTitle
                  : item.title
                : item.title
            }
            bannerDesc={item.desc}
            ctaURL={item.ctaURL}
            ctaText={item.ctaText}
            featuredText={item.featuredText}
            featuredImg={item.featuredImg}
            titleIcon={item.titleIcon}
            bannerClass={item?.class}
          />
        </div>
      )}
    </SwiperSlide>
  ));

  const mediaList = mediaArr.map((item, i) => {
    return (
      <>
        <SwiperSlide key={i}>
          <a href={item.link} target="_blank" rel="noreferrer">
            <div className="logo_wrapper">
              <img
                height={58}
                src={item.img}
                alt={item.alt}
                loading="lazy"
                className="brandlogo"
              />
            </div>
          </a>
        </SwiperSlide>
      </>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="bannersec">
          <Swiper
            modules={[Autoplay, Pagination]}
            grabCursor={false}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
          >
            {bannerList}
          </Swiper>
        </section>

        <section className="home_sec2">
          <img
            width={1440}
            height={840}
            src={
              windowWidth > 600
                ? newImages.aboutBNY.image
                : newImages.aboutBNYMB.image
            }
            alt="exciting"
            className="sec2_bg_img"
            loading="lazy"
          />
          <div className="my_container">
            <div className="text_container">
              <h3 className="section_heading">
                An Exciting Opportunity for Beauty in India
              </h3>
              <p className="section_desc">
                Created by The Estée Lauder Companies’ New Incubation Ventures
                with lead partner Nykaa, the third edition of the BEAUTY&YOU
                India program welcomes India-focused companies from all over the
                world in the categories of skin care, hair care, color cosmetics
                and fragrances.
              </p>
            </div>
          </div>
        </section>

        <section className="home_sec4 divider_line">
          <div className="my_container">
            <h3 className="judges_heading">OUR EXTRAORDINARY JUDGES</h3>
            <div className="judges_wrapper">
              <div className="row">
                <div className="col-sm-4 col-md-4 p-0">
                  <div className="judge_profile">
                    <img
                      width={320}
                      height={420}
                      src={images.shanapotraithomejudge.image}
                      alt={images.shanapotraithomejudge.alt}
                      className="judge_img"
                    />
                    <h6 className="judge_name">Shana Randhava</h6>
                    <p className="judge_desc">
                      SENIOR VICE PRESIDENT, NEW INCUBATION VENTURES, THE ESTÉE
                      LAUDER COMPANIES
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 p-0">
                  <div className="judge_profile">
                    <img
                      width={320}
                      height={420}
                      src={images.anchitnayarjudge.image}
                      alt={images.anchitnayarjudge.alt}
                      className="judge_img"
                    />
                    <h6 className="judge_name">ANCHIT NAYAR</h6>
                    <p className="judge_desc">
                      EXECUTIVE DIRECTOR & CEO,
                      <br />
                      NYKAA BEAUTY
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 p-0">
                  <div className="judge_profile">
                    <img
                      width={320}
                      height={420}
                      src={images.katrinakaifjudge.image}
                      alt={images.katrinakaifjudge.alt}
                      className="judge_img"
                    />
                    <h6 className="judge_name">KATRINA KAIF</h6>
                    <p className="judge_desc">
                      ACTOR AND CO-FOUNDER,
                      <br />
                      KAY BEAUTY
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_wrapper">
              <Link
                to={judges}
                className="project-button project_button mincontent"
              >
                DISCOVER ALL JUDGES
              </Link>
            </div>
          </div>
        </section>

        <section className="home_sec3 divider_line">
          <div className="my_container">
            {quoteArr && (
              <>
                <Swiper
                  loop
                  spaceBetween={0}
                  pagination={{ el: ".swiper-pagination", clickable: true }}
                  modules={[Pagination]}
                  className="quote_swiper"
                  grabCursor={false}
                  autoHeight={true}
                >
                  {quoteArr.map((data, index) => (
                    <SwiperSlide key={`quote-slide-${index}`}>
                      <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6 p-0">
                          <img
                            width={720}
                            height={819}
                            src={data.img}
                            alt={data.imgAlt}
                            className="quote_potrait"
                            loading="lazy"
                          />
                          <div className="swiper-pagination"></div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 p-0">
                          <div className="text_container">
                            <h5
                              className="quote_text"
                              dangerouslySetInnerHTML={{ __html: data.quote }}
                            />
                            <h6
                              className="name"
                              dangerouslySetInnerHTML={{ __html: data.name }}
                            />
                            <p
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.designation,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
          </div>
        </section>

        <section className="home_sec5">
          <div className="my_container">
            <div className="text_container">
              <h3 className="prize_heading">MAIN PRIZES</h3>
              <p className="prize_desc">
                Based on specific application {windowWidth < 600 && <br />}
                criteria and submissions.
                <br />
                Applications due AUGUST 29<sup>th</sup> 2024
              </p>
            </div>
            <div className="categories_wrapper">
              <div className="category_container grow_category">
                <h5 className="category_heading">GROW</h5>
                <h6 className="category_desc">
                  In-market beauty concepts in the categories of skin care, make
                  up, hair care, personal fragrance and home fragrance.
                </h6>
                <p className="section_desc prize_amount">
                  UP TO INR 1.25 crores / $150K USD
                </p>
              </div>

              <div className="category_container imagine_category">
                <h5 className="category_heading">IMAGINE</h5>
                <h6 className="category_desc">
                  Pre-launch beauty concepts in the categories of skin care,
                  make up, hair care, personal fragrance and home fragrance.
                </h6>
                <p className="section_desc prize_amount">
                  UP TO INR 62 lakhs / $75K USD
                </p>
              </div>

              <div className="category_container create_category">
                <h5 className="category_heading">CREATE</h5>
                <h6 className="category_desc">
                  The next generation of Creative Talent (photographers,
                  filmmakers, etc.) submitting work focusing on their
                  interpretation of Indian beauty.
                </h6>
                <p className="section_desc prize_amount">
                  UP TO INR 41 lakhs / $50K USD
                </p>
              </div>
            </div>
            <div className="btn_wrapper">
              <Link to={awardsURL} className="project-button project_button">
                APPLY NOW
              </Link>
            </div>
          </div>
        </section>

        <section className="home_sec6">
          <div className="my_container">
            <div className="support_row">
              <div className="box box-1">
                <img
                  width={720}
                  height={820}
                  src={newImages.oursupport.image}
                  alt={newImages.oursupport.alt}
                  className="main_img"
                  loading="lazy"
                />
              </div>
              <div className="box box-2">
                <div className="text_container grow_text_container">
                  <h5 className="sec_heading support_heading">OUR SUPPORT</h5>
                  <div className="desc_wrapper first_desc_wrapper">
                    <p className="sec_desc">
                      By participating in the program, all applicants will have
                      access to the following:
                    </p>
                  </div>
                  <div className="desc_wrapper">
                    <p className="desc_pointer">FINANCIAL SUPPORT</p>
                    <p className="sec_desc">
                      A total prize pool of up to 4 crores (approximately
                      $500,000 USD) across the entire program for the most
                      innovative, inspiring and creative ideas.
                    </p>
                  </div>
                  <div className="desc_wrapper">
                    <p className="desc_pointer">MENTORSHIP</p>
                    <p className="sec_desc">
                      Access to experts from across the beauty landscape:
                      product and content development, brand building, finance,
                      operations and supply chain.
                    </p>
                  </div>
                  <div className="desc_wrapper">
                    <p className="desc_pointer">AWARENESS</p>
                    <p className="sec_desc">
                      National and international press for the winners that
                      opens up diverse opportunities for them in the global
                      beauty space along with amplification on the
                      highly-coveted Estée Lauder Companies platforms.
                    </p>
                  </div>
                  <div className="desc_wrapper">
                    <p className="desc_pointer">DISTRIBUTION SUPPORT</p>
                    <p className="sec_desc">
                      Valuable guidance on how to scale and reach consumers at
                      both local and global levels.
                    </p>
                  </div>
                  <div className="desc_wrapper">
                    <p className="desc_pointer">1:1 EXCLUSIVE BOOTCAMP</p>
                    <p className="sec_desc">
                      Exclusive BEAUTY&YOU Bootcamp With India’s Leading
                      Founders and Experts
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="masterclass_row">
              <div className="box box-1 order-sm-2 order-md-2 order-lg-2">
                <img
                  width={720}
                  height={820}
                  src={newImages.ourmasterclasses.image}
                  alt={newImages.ourmasterclasses.alt}
                  className="main_img"
                  loading="lazy"
                />
              </div>
              <div className="box box-2 order-sm-1 order-md-1 order-lg-1 divider_line">
                <div className="text_container masterclass_text_container">
                  <h5 className="sec_heading masterclass_heading">
                    OUR MASTERCLASSES
                  </h5>
                  <div className="desc_wrapper">
                    <p className="sec_desc">
                      What does it take to succeed in one of the most creative
                      businesses in the world?
                      <br />
                      <br />
                      Access our library of masterclasses for the fundamentals
                      of building a beauty business from scratch. Learn from
                      creative disruptors, storytellers and founders as they
                      share everything that you need to know to create an iconic
                      beauty brand.
                      <br />
                      <br />
                      2024 masterclasses are now live.
                    </p>
                  </div>
                  <div className="cta_wrapper">
                    <Link
                      to={masterClass}
                      className="project-button mincontent"
                    >
                      discover our masterclasses
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home_sec7">
          <div className="my_container">
            <h3 className="press_heading">Press</h3>
          </div>
          <div className="press_swiper_wrapper">
            <Swiper
              modules={[Autoplay]}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                600: {
                  slidesPerView: 3,
                  spaceBetween: 100,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 100,
                },
                1800: {
                  slidesPerView: 9,
                  spaceBetween: 100,
                },
              }}
            >
              {mediaList}
            </Swiper>
          </div>
          <div className="btn_wrapper">
            <Link to={pressURL} className="project-button mincontent">
              READ ALL PRESS
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Homepage;
